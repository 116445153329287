import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { usePorts } from "../../../Hooks/usePorts";
import { useTranslation } from "react-i18next";
import { usePortVisit } from "../../../Hooks/usePortVisit";

export default () => {
    const { portVisit: havenBezoek, setPortVisit: setHavenBezoek, errors } = usePortVisit();
    const haven = havenBezoek && havenBezoek.haven && havenBezoek.haven.id !== undefined ? havenBezoek.haven.id : '';
    const havenbedrijf = havenBezoek.havenbedrijf.id !== undefined ? havenBezoek.havenbedrijf.id : '';
    const { ports, loading } = usePorts();
    const { t } = useTranslation();

    const getPortById = (id) => {
        if (Object.keys(ports).length > 0) {
            let match = ports.data.filter((item) => {
                return (item.id === id);
            })

            return match.length ? match[0] : {};
        }
    }

    const getPortCompanies = (haven) => {
        let port = getPortById(haven);
        if (port) {
            return port.attributes?.havenbedrijven.data !== undefined ? port.attributes?.havenbedrijven.data : [];
        }
    }

    const [portCompanies, setPortCompanies] = useState(getPortCompanies(haven));

    const updateHavenBezoek = (id) => {
        if (haven === id) {
            return;
        }

        setHavenBezoek({
            ...havenBezoek,
            haven: getPortById(id),
            havenbedrijf: {}
        })

        setPortCompanies(getPortCompanies(id));
    }

    const getPortCompanyById = (id) => {
        let match = portCompanies.filter((item) => {
            return (item.id === id);
        })

        return match.length ? match[0] : {};
    }

    useEffect(() => {
        setPortCompanies(getPortCompanies(haven)); // eslint-disable-next-line
    }, [ports])


    return (
        <>
            <Grid item xs={6}>
                <TextField
                    select
                    id="outlined-select-haven"
                    className="port-select"
                    label={t('havenbezoek.haven')}
                    value={haven}
                    name="haven"
                    onChange={(event) => updateHavenBezoek(event.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    disabled={loading}
                    error={(errors.hasOwnProperty('haven') && errors.haven)}
                >
                    {ports.data && ports.data.length > 0 && ports.data.map((haven, index) => (
                        <MenuItem key={haven.id} value={haven.id}>
                            {t('havens.' + haven.attributes.name.toLowerCase())}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            {(portCompanies !== undefined) && (portCompanies.length > 0) &&
                <Grid item xs={6}>
                    <TextField
                        select
                        id="outlined-select-haven"
                        className="port-select"
                        label={t('havenbezoek.havenbedrijf')}
                        value={havenbedrijf}
                        name="havenbedrijf"
                        onChange={(event) => {
                            setHavenBezoek({
                                ...havenBezoek,
                                havenbedrijf: getPortCompanyById(event.target.value),
                            })
                        }}
                        variant="outlined"
                        fullWidth
                        disabled={(loading || (portCompanies.length < 1))}
                        error={(errors.hasOwnProperty('havenbedrijf') && errors.havenbedrijf)}
                    >
                        <MenuItem key={0} value={0}>
                            {t('havenbezoek.havenbedrijf_nvt')}
                        </MenuItem>
                        {portCompanies.map((portCompany, index) => (
                            <MenuItem key={portCompany.id} value={portCompany.id}>
                                {portCompany.attributes.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            }
        </>
    )
}
