import {useState} from "react";
import useFormUtils from "./useFormUtils";


const useForm = (config, customValidations, customControls) => {
    const {formatAlphaNumericOnly} = useFormUtils();
    const validations = {
        ...customValidations,
    };
    const controls = {
        alphaNumeric: (text) => {
            return formatAlphaNumericOnly(text);
        },
        ...customControls,
    };
    const errorsInit = () => {
        let errors = {};
        Object.keys(config).forEach(item => {
            errors[item] = {
                isError: false,
                errorMsg: '',
            }
        });

        return errors;
    }
    const [errors] = useState(errorsInit());
    const control = (value, name, ignoreEmpty = true) => {
        if (!config.hasOwnProperty(name)) {
            return value;
        }

        Object.keys(config[name]).forEach(key => {
            if (controls.hasOwnProperty(key)) {
                value = controls[key](value);
            }
        })

        return value;
    };
    const validate = (value, name, ignoreEmpty = true) => {
        if (!config.hasOwnProperty(name)) {
            return null;
        }

        let errorDetected = false;
        Object.keys(config[name]).forEach(key => {
            if (validations.hasOwnProperty(key)) {
                const validationError = validations[key](value);
                if (validationError) {
                    errorDetected = true;
                    errors[name].isError = true;
                    errors[name].errorMsg = validationError;
                }
            }
        })

        if (!errorDetected) {
            errors[name].isError = false;
            errors[name].errorMsg = '';
        }
    };
    const validateBatch = (values, ignoreEmpty) => {
        Object.keys(values).forEach(item => {
            if (!values.hasOwnProperty(item)) {
                return null;
            }

            validate(values[item], item, ignoreEmpty);
        })

        return isFormValid();
    };
    const isFormValid = () => {
        let isValid = true;
        Object.keys(errors).forEach(error => {
            if (error.isError) {
                isValid = false;
            }
        })

        return isValid;
    }

    return {errors, validate, control, isFormValid, validateBatch};
}
export default useForm;
