import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import {usePortVisit} from "../../../Hooks/usePortVisit";

export default () => {
    const {validate, submit} = usePortVisit();
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();

    return(
        <>
            <Grid container justifyContent="flex-end">
                <Button label="Submit"
                        primary="true"
                        variant="contained"
                        className="havenbezoek-trigger-dialog"
                        onClick={() => validate(() => {setIsOpen(true)})}
                        color="primary">{t('havenbezoek.btn-melden')}
                </Button>
            </Grid>
            <div className="confirmation">
                <Dialog open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="dialog-confirmation" className="dialog">
                    <DialogTitle id="form-dialog-confirmation" className="dialog-title">{t('havenbezoek.confirmation-title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span>{t('havenbezoek.confirmation-text')}</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="flex-end">
                            <Box>
                                <Button
                                    onClick={() => setIsOpen(false)}
                                    color="primary"
                                    variant="outlined">
                                    {t('havenbezoek.continue-editing')}
                                </Button>
                            </Box>
                            <Button
                                label="Submit"
                                primary="true"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setIsOpen(false);
                                    submit()
                                }}
                            >{t('havenbezoek.btn-melden')}</Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
