import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

const Modal = ({showModal, children, reference, title, closeCallback, className, defaultStyles = true}) => {


    return (
        <div className="confirmation">
            <Dialog open={showModal} onClose={() => closeCallback(!showModal)} aria-labelledby="dialog-confirmation" className="dialog">
                <DialogTitle id="form-dialog-confirmation" className="dialog-title">{title}</DialogTitle>
                {children}
            </Dialog>
        </div>
    )
}

export default Modal