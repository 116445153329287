import React from 'react';
import Modal from "./Modal";
import {useTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

const DeletionModal = ( props ) => {
    const {t} = useTranslation()

    return (
        <Modal {...props}>
            <div className={'actions delete-user d-flex'}>
                <DialogContent>
                    <DialogContentText>
                        <span>{props.notificationText}</span>
                    </DialogContentText>
                    {props.errorText && <p className={'error'}>{props.errorText}</p>}
                </DialogContent>
                <DialogActions style={{paddingRight: 24, paddingLeft: 24}}>
                    <Grid container justifyContent="flex-end">
                        <Box>
                            <Button
                                onClick={() => props.closeModal()}
                                color="primary"
                                variant="outlined"
                                style={{marginRight: 10}}
                            >
                                {t('algemeen.cancel')}
                            </Button>
                        </Box>
                        <Button
                            label="Submit"
                            primary="true"
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={(props.errorText !== null) || props.loading}
                            onClick={() => props.onClickDelete()}
                            style={{marginRight: 0}}
                        >
                            {props.loading ? t('algemeen.loading') : props.deleteButtonText}
                        </Button>
                    </Grid>
                </DialogActions>
            </div>
        </Modal>
    )
}

export default DeletionModal