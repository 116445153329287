import React from 'react';
import ReactMarkdown from "react-markdown";
import Grid from '@material-ui/core/Grid';

import { useHelpPage } from '../../Hooks/useHelpPage';

export default function SupportContent(props) {
    const {helpPage} = useHelpPage();

    return (
        <section className="terms-content">
            <Grid item className="article">
                <Grid container className="item">
                    <Grid container className="item">
                        <Grid item xs={12}>
                            <ReactMarkdown>{helpPage.Content}</ReactMarkdown>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );
}
