import React, {useEffect, useState} from "react";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import nlLocale from "date-fns/locale/nl";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {usePortVisit} from "../../../Hooks/usePortVisit";
import {validateDates as valDates} from "../../../utils/dates";

export default () => {
    const {portVisit: havenBezoek, setPortVisit: setHavenBezoek} = usePortVisit();
    const aankomstdatum = new Date(havenBezoek.aankomstdatum);
    const vertrekdatum = new Date(havenBezoek.vertrekdatum);
    const [vertrekError, setVertrekError] = useState(false);
    const {t} = useTranslation();

    const validateDates = (depart, arrival) => {
        const hasError = valDates(depart, arrival);

        if (hasError !== vertrekError) {
            setVertrekError(hasError);
        }
    }

    useEffect(() => {
        validateDates(); // eslint-disable-next-line
    }, [])

    return (
        <>
            <Grid item xs={12} className="timepickers">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <KeyboardDateTimePicker
                                margin="normal"
                                id="aankomstDatum"
                                label={t('havenbezoek.aankomstdatumtijd')}
                                value={aankomstdatum}
                                onChange={(date) => {
                                    setHavenBezoek({
                                        ...havenBezoek,
                                        aankomstdatum: date,
                                        aankomsttijd: date,
                                    })
                                    validateDates(vertrekdatum, date);
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                format="dd-MM-yyyy HH:mm"
                                required
                                invalidDateMessage={t('algemeen.datepicker.invalid_date')}
                                ampm={false}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDateTimePicker
                                margin="normal"
                                id="vertrekDatum"
                                label={t('havenbezoek.vertrekdatumtijd')}
                                value={vertrekdatum}
                                onChange={(date) => {
                                    setHavenBezoek({
                                        ...havenBezoek,
                                        vertrekdatum: date,
                                        vertrektijd: date,
                                    })
                                    validateDates(date, aankomstdatum)
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                format="dd-MM-yyyy HH:mm"
                                required
                                invalidDateMessage={t('algemeen.datepicker.invalid_date')}
                                ampm={false}
                            />
                            {vertrekError &&
                            <span className="error">{t('algemeen.error.vertrektijd')}</span>
                            }
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
        </>
    )
}