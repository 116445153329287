import { useSelector, useDispatch } from "react-redux";
import { updatePortVisit, setPortVisitError } from "../Reducers/portVisitReducer";
import { validateDates } from "../utils/dates";
import { useMutation } from "@apollo/client";
import { CREATE_HAVENBEZOEK } from "../Model/Mutations";
import { useUser } from "./useUser";
import { useNotifications } from "./useNotifications";
import { useTranslation } from "react-i18next";

export function usePortVisit() {
    const portVisit = useSelector(state => state.portVisit.visit);
    const errors = useSelector(state => state.portVisit.errors);
    const pending = useSelector(state => state.portVisit.pending);
    const { user } = useUser();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { appendNotification: addNotification } = useNotifications();
    const setPortVisit = (state) => {
        state.aankomstdatum = state.aankomstdatum.toString();
        state.aankomsttijd = state.aankomsttijd.toString();
        state.vertrekdatum = state.vertrekdatum.toString();
        state.vertrektijd = state.vertrektijd.toString();

        dispatch(updatePortVisit(state));
    }
    const onCompleted = (data) => {
        localStorage.removeItem('newVisit');
        addNotification({ message: t('havenbezoek.info.success'), type: 'success', duration: 20000 })
        window.location.href = '/';
    }
    const onError = () => {
        dispatch(setPortVisitError({
            ...errors,
            serverError: true,
        }))
    }
    const [savePortVisit, { data, loading: mutationLoading }] = useMutation(CREATE_HAVENBEZOEK, {
        onCompleted: (data) => {
            onCompleted(data);
        },
        onError: (error) => {
            onError();
        }
    });
    const validate = (callback = null) => {
        validateVisit(portVisit, dispatch, callback);
    }
    const submit = (callback = null) => {
        submitVisit(savePortVisit, data, mutationLoading, user, portVisit, callback, dispatch);
    }

    return { portVisit, setPortVisit, errors, pending, validate, submit }
}

function checkVisit(portVisit, validateBilling = false) {
    let errors = {
        formHasError: false,
    };
    const setFormError = (name = null, isError = true) => {
        if (!isError) return;

        errors.formHasError = true;
        if (name !== null) {
            errors[name] = isError;
        }
    }

    if (!validateDates(portVisit.aankomstdatum, portVisit.vertrekdatum)) {
        setFormError();
    }

    if (portVisit.afwijkend_factuuradres || validateBilling) {
        setFormError('factuuradres_bedrijfsnaam', (portVisit.factuuradres_bedrijfsnaam.length < 1));
        setFormError('factuuradres_adres', (portVisit.factuuradres_adres.length < 1));
        setFormError('factuuradres_postcode', (portVisit.factuuradres_postcode.length < 1));
        setFormError('factuuradres_plaats', (portVisit.factuuradres_plaats.length < 1));
        setFormError('factuuradres_land', (portVisit.factuuradres_land.length < 1));
        setFormError('factuuradres_kvk', ((portVisit.factuuradres_kvk?.length < 1) && (parseInt(portVisit.factuuradres_land) === 103)));
        setFormError('factuuradres_btw', ((portVisit.factuuradres_btw?.length < 1) && (parseInt(portVisit.factuuradres_land) !== 103)));
    }
    let billingHasError = false;
    Object.keys(errors).forEach(item => {
        if (item.startsWith('factuuradres_')) {
            billingHasError = true;
        }
    });
    if (billingHasError) {
        setFormError('billing', billingHasError);
    }

    setFormError('haven', (portVisit.haven.id === undefined));
    setFormError('schip', (portVisit.schip.id === undefined));

    setFormError('loading_teu', ((portVisit.visit_type_loading) && (portVisit.loading_unit === 'containers') && (portVisit.loading_teu < 1)));
    setFormError('unloading_teu', ((portVisit.visit_type_unloading) && (portVisit.unloading_unit === 'containers') && (portVisit.unloading_teu < 1)));

    setFormError('loading_ton_goods', ((portVisit.visit_type_loading) && (portVisit.loading_unit === 'goods') && (portVisit.loading_shipping_contents.ton_goods < 1)));
    setFormError('unloading_ton_goods', ((portVisit.visit_type_unloading) && (portVisit.unloading_unit === 'goods') && (portVisit.unloading_shipping_contents.ton_goods < 1)));

    return errors;
}

function validateVisit(portVisit, dispatch, callback) {
    const errors = checkVisit(portVisit);

    if (callback !== null && !errors.formHasError) {
        callback();
    }
    dispatch(setPortVisitError(errors));

    return errors.formHasError;
}

function submitVisit(savePortVisit, data, loading, user, visitData, callback, dispatch) {
    const prepared = prepareVisitForPost(visitData, user);

    const errors = checkVisit(prepared, true);
    if (errors.formHasError) {
        dispatch(setPortVisitError(errors));

        return;
    }

    savePortVisit({
        variables: {
            ...prepared,
        },
    });

    if (callback !== null) {
        callback();
    }
}

function prepareVisitForPost(data, user) {
    const prepared = { ...data };

    prepared.user = user.data.user.data.id;
    prepared.schipper = user;

    const aankomst = new Date(prepared.aankomstdatum);
    const vertrek = new Date(prepared.vertrekdatum);
    prepared.aankomstdatum = aankomst.getFullYear() + '-' + ('0' + (aankomst.getMonth() + 1)).slice(-2) + '-' + ('0' + aankomst.getDate()).slice(-2);
    prepared.aankomsttijd = new Date(prepared.aankomsttijd).toISOString();
    prepared.vertrekdatum = vertrek.getFullYear() + '-' + ('0' + (vertrek.getMonth() + 1)).slice(-2) + '-' + ('0' + vertrek.getDate()).slice(-2);
    prepared.vertrektijd = new Date(prepared.vertrektijd).toISOString();

    const billingSource = prepared.schip.afwijkend_factuuradres ? prepared.schip.address : user;
    if (!prepared.afwijkend_factuuradres) {
        prepared.factuuradres_bedrijfsnaam = billingSource.data.factuur_bedrijfsnaam;
        prepared.factuuradres_adres = billingSource.data.factuur_adres;
        prepared.factuuradres_huisnr = billingSource.data.factuuradres_huisnr;
        prepared.factuuradres_huisnrtoevoeging = billingSource.data.factuuradres_huisnrtoevoeging;
        prepared.factuuradres_postcode = billingSource.data.factuur_postcode;
        prepared.factuuradres_plaats = billingSource.data.factuur_plaats;
        prepared.factuuradres_land = billingSource.data.factuur_land.toString();
        prepared.factuuradres_kvk = (billingSource.data.factuur_kvk !== null) ? billingSource.data.factuur_kvk.toString() : '';
        prepared.factuuradres_btw = (billingSource.data.factuur_btw !== null) ? billingSource.data.factuur_btw.toString() : '';
    }
    prepared.factuuradres_land = prepared.factuuradres_land.toString();
    prepared.factuuradres_kvk = (prepared.factuuradres_kvk !== null) ? prepared.factuuradres_kvk.toString() : '';
    prepared.factuuradres_btw = (prepared.factuuradres_btw !== null) ? prepared.factuuradres_btw.toString() : '';

    // Kvk is not applicable outside The Netherlands
    if (prepared.factuuradres_land !== '103') {
        prepared.factuuradres_kvk = ''
    }

    // unset unit if visit_type
    if (!prepared.visit_type_loading) {
        prepared.loading_unit = 'not_applicable';
    }
    if (!prepared.visit_type_unloading) {
        prepared.unloading_unit = 'not_applicable';
    }

    return prepared;
}
