import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {Suspense} from "react";
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_nl from "./translations/nl/common.json";
import common_en from "./translations/en/common.json";
import common_de from "./translations/de/common.json";
import common_fr from "./translations/fr/common.json";

const Loader = () => <div>loading...</div>;

i18next.init({
    interpolation: {escapeValue: false},  // React already does escaping
    lng: 'nl',                              // language to use
    defaultNS: 'common',
    resources: {
        nl: {
            common: common_nl
        },
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        de: {
            common: common_de
        },
        fr : {
            common: common_fr
        },
    },
    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a'],
    }
});

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <Suspense fallback={<Loader/>}>
            <App/>
        </Suspense>
    </I18nextProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
