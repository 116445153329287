import { FormControl, Grid, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePortVisit } from "../../../Hooks/usePortVisit";

export default () => {
    const {portVisit: havenBezoek, setPortVisit: setHavenBezoek, errors} = usePortVisit();
    const visitTypeUnLoading = havenBezoek.visit_type_unloading;
    const unit = havenBezoek.unloading_unit;
    const shippingContents = havenBezoek.unloading_shipping_contents;
    const tonGoods = shippingContents.ton_goods;
    const {t} = useTranslation();

    const updateContainers = (name, value) => {
        setHavenBezoek({
            ...havenBezoek,
            unloading_shipping_contents: {
                ...shippingContents,
                [name]: value,
            }
        })
    }

    useEffect(() => {
        let tue = 0;
        tue += shippingContents.containers20 * 20;
        tue += shippingContents.containers40 * 40;
        tue += shippingContents.containers45 * 45;

        tue = Math.round((tue / 20) * 10) / 10;

        setHavenBezoek({
            ...havenBezoek,
            unloading_teu: tue,
        })
        // eslint-disable-next-line
    }, [shippingContents]);

    return (
        <>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                    {(unit === 'goods' && visitTypeUnLoading) &&
                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="ton_goods"
                            label={t('havenbezoek.ton_goods')}
                            type="number"
                            name="ton_goods"
                            defaultValue={tonGoods}
                            onChange={e => setHavenBezoek({
                                ...havenBezoek,
                                unloading_shipping_contents: {
                                    ...shippingContents,
                                    ton_goods: e.target.value,
                                }
                            })}
                            margin="normal"
                            variant="outlined"
                            error={(errors.hasOwnProperty('ton_goods') && errors.unloading_ton_goods) || tonGoods.length > 4}
                            helperText={tonGoods.length > 4 ? t('havenbezoek.ton_goods_error') : ''}
                            InputProps={{ inputProps: { min: 0, max: 4 } }}
                            required
                        />
                    </FormControl>
                    }
                    {(unit === 'containers' && visitTypeUnLoading) &&
                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="containers_20"
                            label={t('havenbezoek.containers_20')}
                            type="number"
                            name="containers_20"
                            defaultValue={shippingContents.containers20}
                            onChange={e => updateContainers('containers20', e.target.value)}
                            margin="normal"
                            variant="outlined"
                            error={(errors.hasOwnProperty('teu') && errors.unloading_teu)}
                            required
                        />
                        <TextField
                            id="containers_40"
                            label={t('havenbezoek.containers_40')}
                            type="number"
                            name="containers_40"
                            defaultValue={shippingContents.containers40}
                            onChange={e => updateContainers('containers40', e.target.value)}
                            margin="normal"
                            variant="outlined"
                            error={(errors.hasOwnProperty('teu') && errors.unloading_teu)}
                            required
                        />
                        <TextField
                            id="containers_45"
                            label={t('havenbezoek.containers_45')}
                            type="number"
                            name="containers_45"
                            defaultValue={shippingContents.containers45}
                            onChange={e => updateContainers('containers45', e.target.value)}
                            margin="normal"
                            variant="outlined"
                            error={(errors.hasOwnProperty('teu') && errors.unloading_teu)}
                            required
                        />
                    </FormControl>
                    }
                </Grid>
                {(unit === 'containers' && visitTypeUnLoading) &&
                <Grid item xs={6}>
                    <div className="teu">
                        <h3>{t('havenbezoek.teu')}</h3>
                        <p>{t('havenbezoek.teu_description')}</p>
                        <p><b>{t('havenbezoek.total_teu') + ' ' + havenBezoek.unloading_teu + ' ' + t('havenbezoek.teu')}</b></p>
                    </div>
                </Grid>
                }
            </Grid>
        </>
    );
}
