import { useSelector, useDispatch } from "react-redux";
import { addNotification, removeNotification} from "../Reducers/notificationsReducer";

export function useNotifications() {
    const notifications = useSelector(state => state.notifications.queue);
    const dispatch = useDispatch();

    const appendNotification = (item) => {
        dispatch(addNotification(item));
    }
    const remove = (item) => {
        dispatch(removeNotification(item));
    }

    return {appendNotification, notifications, remove}
}