import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import UpdateInfo from '../UpdateInfo';
import helper from "../../assets/helper/";
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { usePortVisits } from "../../Hooks/usePortVisits";
import { usePage } from "../../Hooks/usePage";
import Overview from "./Overview";

export default () => {
    const { loading: visitsLoading } = usePortVisits();
    const { page: content, loading: contentLoading, is404 } = usePage({ tag: 'port_visits' })
    const userStatus = helper.getUserInfo();
    const allowNewVisit = (userStatus.complete === 'true');
    const { t } = useTranslation();

    useEffect(() => {
        if (!contentLoading) {
            document.title = content.Title;
        }
    }, [content, contentLoading])

    return (
        <div className="havenbezoeken">
            <Grid container className="havenbezoeken-container">
                <Grid item xs={12} className="notes">
                    <UpdateInfo />
                </Grid>
                <Grid container className="header-title">
                    {(!contentLoading && !is404) &&
                        <Grid item xs={12} sm={9}>
                            <h1>{content.Title}</h1>
                        </Grid>
                    }
                    <Grid item xs={12} sm={3} className="header-action">
                        {allowNewVisit && (
                            <Link className="btn" to={'/havenbezoek/nieuw'}>
                                <Button label="Submit"
                                    primary="true"
                                    {...(!allowNewVisit && { disabled: true })}
                                    variant="contained"
                                    color="primary">{t('havenbezoeken.btn-new')}</Button>
                            </Link>
                        )}
                        {!allowNewVisit && (
                            <Button label="Submit"
                                primary="true"
                                {...(!allowNewVisit && { disabled: true })}
                                variant="contained"
                                color="primary">{t('havenbezoeken.btn-new')}</Button>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {(!contentLoading && !is404) && content.Content}
                </Grid>
            </Grid>
            {visitsLoading && <CircularProgress />}
            {!visitsLoading &&
                <>
                    <Overview />
                </>
            }
        </div>
    )
}
