import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from "@material-ui/core/Tooltip";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountries } from "../../Hooks/useCountries";
import { useNotifications } from "../../Hooks/useNotifications";
import { useUser } from "../../Hooks/useUser";
import helper from "../../assets/helper";
import AppContext from "../../utils/AppContext";
import request from "../../utils/request";

export default () => {
    const { user, loading: userLoading, error: userError } = useUser();
    const appContext = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [skipperId, setSkipperId] = useState('');
    const [profile, setProfile] = useState({
        'taal': 'nl',
        'name': '',
        'email': '',
        'bedrijfsnaam': '',
        'telefoon_schip': '',
        'telefoon_kantoor': '',
        'eigenaar_adres': '',
        'eigenaar_postcode': '',
        'eigenaar_plaats': '',
        'eigenaar_land': '103',
        'eigenaar_kvk': null,
        'eigenaar_btw': '',
        'afwijkend_factuuradres': false,
        'factuur_bedrijfsnaam': '',
        'factuur_adres': '',
        'factuur_huisnr': '',
        'factuur_huisrnrtoevoeging': '',
        'factuur_postcode': '',
        'factuur_plaats': '',
        'factuur_land': '103',
        'factuur_kvk': null,
        'factuur_btw': null
    });
    const { countries, loading: countriesLoading } = useCountries();
    const [error, setError] = useState('');
    const [userVatError, setUserVatError] = useState(false);
    const [billingVatError, setBillingVatError] = useState(false);
    const { t, i18n } = useTranslation();
    const { appendNotification: addNotification } = useNotifications();
    const languages = [
        {
            value: 'nl',
            label: 'Nederlands',
        },
        {
            value: 'en',
            label: 'English',
        },
        {
            value: 'de',
            label: "Deutsch",
        },
        {
            value: 'fr',
            label: 'Français',
        },
    ];
    const checkVatUrl = process.env.REACT_APP_SYNC + '/checkvat';

    useEffect(() => {
        document.title = t('profiel.title');
        if (!userLoading && (user !== undefined)) {

            if (user.schipperId == null) {
                let data = {
                    ...profile,
                    ...user,
                    email: user.data.user.email,
                    user: { data: { attributes: { email: user.data.user.email } } }
                }
                delete data.__typename;
                delete data.__user;
                setProfile(data);
                setSkipperId(data.schipperId);
                setLoading(false);
            } else {
                let data = {
                    ...profile,
                    ...user,
                    email: user.data.user.email,
                }
                delete data.__typename;
                delete data.__user;
                setProfile(data.data);
                setSkipperId(data.schipperId);
                setLoading(false);
            }

        } // eslint-disable-next-line
    }, [user, userLoading, t]);

    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let setState = false;
        let regex_textonly = /[^a-z0-9]/gi;

        switch (target.name) {
            case 'eigenaar_btw':
                if (profile.eigenaar_land !== '' && target.value !== '') {
                    // check validatie
                    let countryCode = profile.eigenaar_land;
                    target.value = target.value.replace(regex_textonly, '');
                    let vatNumber = target.value;

                    fetch(checkVatUrl + '?countryCode=' + countryCode + '&vatNumber=' + vatNumber)
                        .then(response => response.json())
                        .then(response => {
                            if (response) {
                                console.log(response);
                                setState = true;
                                setUserVatError(false);
                                setProfile({
                                    ...profile,
                                    [name]: value
                                });

                            } else {
                                setUserVatError(true);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        })
                } else {
                    setState = true;
                }
                break;
            case 'eigenaar_land':
                if (profile.eigenaar_btw !== '') {
                    // check validatie
                    let countryCode = target.value.toString();
                    setProfile({
                        ...profile,
                        eigenaar_btw: profile.eigenaar_btw.replace(regex_textonly, ''),
                    });
                    let vatNumber = profile.eigenaar_btw;

                    fetch(checkVatUrl + '?countryCode=' + countryCode + '&vatNumber=' + vatNumber)
                        .then(response => response.json())
                        .then(response => {
                            if (response) {
                                setState = true;
                                setUserVatError(false);
                                setProfile({
                                    ...profile,
                                    [name]: value,
                                });

                            } else {
                                setUserVatError(true);
                            }
                        })
                        .catch(err => {
                        })
                }
                setState = true;
                break;
            case 'factuur_btw':
                if (profile.factuur_land !== '' && target.value !== '') {
                    // check validatie
                    let countryCode = profile.factuur_land;
                    target.value = target.value.replace(regex_textonly, '');
                    let vatNumber = target.value;

                    fetch(checkVatUrl + '?countryCode=' + countryCode + '&vatNumber=' + vatNumber)
                        .then(response => response.json())
                        .then(response => {
                            if (response) {
                                console.log(response);
                                setState = true;
                                setBillingVatError(false);
                                setProfile({
                                    ...profile,
                                    [name]: value
                                });
                            } else {
                                setBillingVatError(true);
                            }
                        })
                        .catch(err => {
                        })
                } else {
                    setState = true;
                }
                break;
            case 'factuur_land':
                if (profile.factuur_btw !== '') {
                    // check validatie
                    let countryCode = target.value.toString();
                    setProfile({
                        ...profile,
                        factuur_btw: profile.factuur_btw.replace(regex_textonly, ''),
                    });
                    let vatNumber = profile.factuur_btw;

                    fetch(checkVatUrl + '?countryCode=' + countryCode + '&vatNumber=' + vatNumber)
                        .then(response => response.json())
                        .then(response => {
                            if (response) {
                                setState = true;
                                setBillingVatError(false);
                                setProfile({
                                    ...profile,
                                    [name]: value
                                });
                            } else {
                                setBillingVatError(true);
                            }
                        })
                        .catch(err => {
                        })
                }
                setState = true;
                break;

            default:
                setState = true;
        }

        if (setState) {
            setProfile({
                ...profile,
                [name]: value
            });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let regex_textonly = /[^a-z0-9]/gi;
        if (userVatError || billingVatError) {
            return false;
        } else {
            setProfile({
                ...profile,
                eigenaar_btw: profile.eigenaar_btw ? profile.eigenaar_btw.replace(regex_textonly, '') : null,
                factuur_btw: profile.factuur_btw ? profile.factuur_btw.replace(regex_textonly, '') : null
            });
        }

        let postUrl;
        let method;
        if (skipperId === '' || skipperId === undefined || skipperId === null) {
            postUrl = process.env.REACT_APP_STRAPI_URL + '/schippers/';
            method = 'POST';
        } else {
            postUrl = process.env.REACT_APP_STRAPI_URL + '/schippers/' + skipperId;
            method = 'PUT';
        }

        setLoading(true);
        let profileData = {
            ...profile,
            eigenaar_land: profile.eigenaar_land.toString(),
            factuur_land: profile.factuur_land.toString(),
        };

        if (!profile.afwijkend_factuuradres) {
            setProfile({
                ...profile,
                factuur_adres: profile.eigenaar_adres
            });

            profileData = {
                ...profileData,
                factuur_adres: profile.eigenaar_adres,
            }

            profileData = {
                ...profileData,
                factuur_bedrijfsnaam: profile.bedrijfsnaam,
                factuur_adres: profile.eigenaar_adres,
                factuur_huisnr: profile.eigenaar_huisnr,
                factuur_huisnrtoevoeging: profile.eigenaar_huisnrtoevoeging,
                factuur_postcode: profile.eigenaar_postcode,
                factuur_plaats: profile.eigenaar_plaats,
                factuur_land: profile.eigenaar_land,
                factuur_kvk: profile.eigenaar_kvk,
                factuur_btw: profile.eigenaar_btw,
            };
        }

        if (profile.eigenaar_kvk?.length < 1) {
            profileData = {
                ...profileData,
                eigenaar_kvk: null,
            }
        }

        if (profileData.factuur_kvk?.length < 1) {
            profileData = {
                ...profileData,
                factuur_kvk: profile.factuur_kvk ?? null,
            }
        }

        let data = { data: profileData };

        request(postUrl, { method: method, body: data })
            .then(postResponse => {
                console.log(postResponse);

                if (user.taal !== profile.taal) {
                    i18n.changeLanguage(profile.taal);
                }
                addNotification({
                    type: 'success',
                    message: t('profiel.info.success'),
                })
                helper.checkUserInfo(postResponse.data.attributes);
                appContext.setUserInfo(helper.getUserInfo())

                // setLoading(false);
                setSkipperId(postResponse.data.id);

                helper.checkUserComplete();
            })
            .catch(postError => {
                console.log(postError);
                setLoading(false);
                setError(postError);
            });
    }

    if (loading || userLoading) return (<CircularProgress />);
    if (error || userError) {
        return (
            <div className="profiel">
                <h1>{t('profiel.title')}</h1>
                {error && <div className="Error">{error}</div>}
                {userError && <div className="Error">{userError.message}</div>}
            </div>
        )
    }
    return (
        <div className="profiel">
            <div className="profiel-container">
                <h1>{t('profiel.title')}</h1>
                <p>{t('profiel.intro')}</p>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                        select
                        id="outlined-select-language"
                        name="taal"
                        className={'language-select active-language-' + profile.taal}
                        label={t('algemeen.language')}
                        value={profile.taal}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    >
                        {languages.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {profile.user.data.attributes.email !== '' &&
                        <Grid container>
                            <Grid item xs={11}>
                                <FormControl fullWidth variant="outlined">
                                    <TextField
                                        label={t('profiel.email')}
                                        type="text"
                                        name="email"
                                        defaultValue={profile.user.data.attributes.email}
                                        margin="normal"
                                        variant="outlined"
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title={t('profiel.info.email')} className="profile-email-tooltip">
                                    <Icon className="icon-info" />
                                </Tooltip>
                            </Grid>
                        </Grid>

                    }

                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="name"
                            label={t('profiel.naam')}
                            type="text"
                            name="name"
                            onChange={handleChange}
                            defaultValue={profile.name}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                    </FormControl>

                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="telefoon_schip"
                            label={t('profiel.telefoon-schip')}
                            type="text"
                            name="telefoon_schip"
                            onChange={handleChange}
                            defaultValue={profile.telefoon_schip}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                    </FormControl>

                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="telefoon_kantoor"
                            label={t('profiel.telefoon-kantoor')}
                            type="text"
                            name="telefoon_kantoor"
                            onChange={handleChange}
                            defaultValue={profile.telefoon_kantoor}
                            margin="normal"
                            variant="outlined"
                        />
                    </FormControl>

                    <h2>{t('profiel.title-adres-eigenaar')}</h2>

                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="bedrijfsnaam"
                            label={t('profiel.bedrijfsnaam')}
                            type="text"
                            name="bedrijfsnaam"
                            onChange={handleChange}
                            defaultValue={profile.bedrijfsnaam}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                    </FormControl>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth required variant="outlined">
                                <TextField
                                    id="eigenaar_adres"
                                    label={t('profiel.adres')}
                                    type="text"
                                    name="eigenaar_adres"
                                    onChange={handleChange}
                                    defaultValue={profile.eigenaar_adres}
                                    margin="normal"
                                    variant="outlined"
                                    required
                                />
                            </FormControl>
                        </Grid>
                        {parseInt(profile.eigenaar_land) !== 103
                            ? null
                            :
                            <Grid item xs={4} >
                                <FormControl fullWidth required variant="outlined">
                                    <TextField
                                        id="eigenaar_huisnr"
                                        label={t('profiel.huisnr')}
                                        type="number"
                                        name="eigenaar_huisnr"
                                        defaultValue={profile.eigenaar_huisnr}
                                        variant="outlined"
                                        margin="normal"
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        {parseInt(profile.eigenaar_land) !== 103
                            ? null
                            :
                            <Grid item xs={4}>
                                <FormControl fullWidth required variant="outlined">
                                    <TextField
                                        id="eigenaar_huisnrtoevoeging"
                                        label={t('profiel.huisnrtoevoeging')}
                                        type="text"
                                        name="eigenaar_huisnrtoevoeging"
                                        defaultValue={profile.eigenaar_huisnrtoevoeging}
                                        variant="outlined"
                                        margin="normal"
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4}>
                            <FormControl fullWidth required variant="outlined">
                                <TextField
                                    id="eigenaar_postcode"
                                    label={t('profiel.postcode')}
                                    type="text"
                                    name="eigenaar_postcode"
                                    onChange={handleChange}
                                    defaultValue={profile.eigenaar_postcode}
                                    margin="normal"
                                    variant="outlined"
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth required variant="outlined">
                                <TextField
                                    id="eigenaar_plaats"
                                    label={t('profiel.plaats')}
                                    type="text"
                                    name="eigenaar_plaats"
                                    onChange={handleChange}
                                    defaultValue={profile.eigenaar_plaats}
                                    margin="normal"
                                    variant="outlined"
                                    required
                                />
                            </FormControl>
                        </Grid>

                        {!countriesLoading &&
                            <Grid item xs={4}>
                                <FormControl fullWidth required variant="outlined">
                                    <TextField
                                        select
                                        id="eigenaar_land"
                                        name="eigenaar_land"
                                        className={'eigenaar_land'}
                                        label={t('profiel.land')}
                                        value={profile.eigenaar_land === '' ? '103' : profile.eigenaar_land}
                                        onChange={handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        required
                                        fullWidth
                                    >
                                        {countries.map(land => {
                                            return (
                                                <MenuItem key={land.attributes.landcode} value={land.attributes.landcode.toString()}>
                                                    {land.attributes.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="eigenaar_kvk"
                            label={t('profiel.kvk')}
                            type="number"
                            name="eigenaar_kvk"
                            onChange={handleChange}
                            defaultValue={profile.eigenaar_kvk}
                            margin="normal"
                            variant="outlined"
                            required={profile.eigenaar_land === '103'}
                        />
                    </FormControl>
                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="eigenaar_btw"
                            label={t('profiel.btw')}
                            type="text"
                            name="eigenaar_btw"
                            onChange={handleChange}
                            defaultValue={profile.eigenaar_btw}
                            margin="normal"
                            variant="outlined"
                            required={profile.eigenaar_land !== '103'}
                        />
                        {userVatError &&
                            <span className="error">{t('algemeen.error.vat-invalid')}</span>
                        }
                    </FormControl>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox name="afwijkend_factuuradres" checked={profile.afwijkend_factuuradres} onChange={handleChange} value="1" color="primary" />
                            }
                            label={t('profiel.afwijkend_factuuradres')}
                        />
                    </Grid>

                    {profile.afwijkend_factuuradres &&
                        <div className="afwijkend factuuradres">
                            <h2>{t('profiel.title-adres-factuur')}
                                <Tooltip title={t('profiel.info.factuuradres')} className="profile-factuuradres-tooltip">
                                    <Icon className="icon-info" />
                                </Tooltip>
                            </h2>

                            <FormControl fullWidth required variant="outlined">
                                <TextField
                                    id="factuur_bedrijfsnaam"
                                    label={t('profiel.bedrijfsnaam')}
                                    type="text"
                                    name="factuur_bedrijfsnaam"
                                    onChange={handleChange}
                                    defaultValue={profile.factuur_bedrijfsnaam}
                                    margin="normal"
                                    variant="outlined"
                                    required
                                />
                            </FormControl>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth required variant="outlined">
                                        <TextField
                                            id="factuur_adres"
                                            label={t('profiel.adres')}
                                            type="text"
                                            name="factuur_adres"
                                            onChange={handleChange}
                                            defaultValue={profile.factuur_adres}
                                            margin="normal"
                                            variant="outlined"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                {parseInt(profile.factuur_land) !== 103
                                    ? null
                                    :
                                    <Grid item xs={4}>
                                        <FormControl fullWidth required variant="outlined">
                                            <TextField
                                                id="factuur_huisnr"
                                                label={t('profiel.huisnr')}
                                                type="text"
                                                name="factuur_huisnr"
                                                onChange={handleChange}
                                                defaultValue={profile.factuur_huisnr}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                {parseInt(profile.factuur_land) !== 103
                                    ? null
                                    :
                                    <Grid item xs={4}>
                                        <FormControl fullWidth required variant="outlined">
                                            <TextField
                                                id="factuur_huisnrtoevoeging"
                                                label={t('profiel.huisnrtoevoeging')}
                                                type="text"
                                                name="factuur_huisnrtoevoeging"
                                                onChange={handleChange}
                                                defaultValue={profile.factuur_huisnrtoevoeging}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4}>
                                    <FormControl fullWidth required variant="outlined">
                                        <TextField
                                            id="factuur_postcode"
                                            label={t('profiel.postcode')}
                                            type="text"
                                            name="factuur_postcode"
                                            onChange={handleChange}
                                            defaultValue={profile.factuur_postcode}
                                            margin="normal"
                                            variant="outlined"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth required variant="outlined">
                                        <TextField
                                            id="factuur_plaats"
                                            label={t('profiel.plaats')}
                                            type="text"
                                            name="factuur_plaats"
                                            onChange={handleChange}
                                            defaultValue={profile.factuur_plaats}
                                            margin="normal"
                                            variant="outlined"
                                            required
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth required variant="outlined">
                                        <TextField
                                            select
                                            id="factuur_land"
                                            name="factuur_land"
                                            className={'factuur_land'}
                                            label={t('profiel.land')}
                                            value={profile.factuur_land === '' ? '103' : profile.factuur_land}
                                            onChange={handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            required
                                            fullWidth
                                        >
                                            {countries.map(land => (
                                                <MenuItem key={land.attributes.landcode} value={land.attributes.landcode.toString()}>
                                                    {land.attributes.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl fullWidth required variant="outlined">
                                <TextField
                                    id="factuur_kvk"
                                    label={t('profiel.kvk')}
                                    type="number"
                                    name="factuur_kvk"
                                    onChange={handleChange}
                                    defaultValue={profile.factuur_kvk}
                                    margin="normal"
                                    variant="outlined"
                                    required={profile.factuur_land === '103'}
                                />
                            </FormControl>

                            <FormControl fullWidth required variant="outlined">
                                <TextField
                                    id="factuur_btw"
                                    label={t('profiel.btw')}
                                    type="text"
                                    name="factuur_btw"
                                    onChange={handleChange}
                                    defaultValue={profile.factuur_btw}
                                    margin="normal"
                                    variant="outlined"
                                    required={profile.factuur_land !== '103'}
                                />
                                {billingVatError &&
                                    <span className="error">{t('algemeen.error.vat-invalid')}</span>
                                }
                            </FormControl>
                        </div>
                    }


                    <Grid container justifyContent="flex-end">
                        <Button
                            label="Submit"
                            primary="true"
                            type="submit"
                            variant="contained"
                            color="primary"
                        >{t('profiel.btn-opslaan')}</Button>
                    </Grid>
                </form>
            </div>
        </div>
    );
}
