import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";


const shipsAdapter = createEntityAdapter();
const shipsSlide = createSlice({
    name: 'ships',
    initialState: shipsAdapter.getInitialState({
        loading: false,
        initialized: false,
        shipTypes: {
            types: [],
            loading: false,
            initialized: false,
        }
    }),
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            state.initialized = true;
        },
        setShips: (state, action) => {
            state.loading = false;
            shipsAdapter.upsertMany(state, action.payload);
        },
        setShipTypesLoading: (state, action) => {
            state.shipTypes.loading = action.payload;
            state.shipTypes.initialized = true;
        },
        setShipTypes: (state, action) => {
            state.shipTypes.types = action.payload;
            state.shipTypes.loading = false;
        },
        removeShip: (state, action) => {
            shipsAdapter.removeOne(state, action.payload.id)
        },
    }
})

export const {setShips, setLoading, setShipTypes, setShipTypesLoading, removeShip} = shipsSlide.actions;
export const {selectAll: selectAllShips, selectById: selectShipById} = shipsAdapter.getSelectors(state => state.ships);
export const shipsReducer = shipsSlide.reducer;