const helper = {
    setUserInfo: function (userInfo) {
        let user = userInfo;
        delete user.id;
        delete user.created_at;
        delete user.updated_at;
        delete user.user;
        if (localStorage.length > 0) {
            // localStorage.setItem('userInfo', JSON.stringify(user));
            this.checkUserInfo(user);
        }
    },
    checkUserInfo(userInfo) {
        let numberFields = 0;
        let numberComplete = 0;
        let mandatoryFields = ['name', 'telefoon_schip', 'bedrijfsnaam', 'eigenaar_adres', 'eigenaar_postcode', 'eigenaar_plaats', 'eigenaar_land'];
        let percentageComplete = 0;

        let schipper = userInfo ?? userInfo.data[0].attributes;

        Object.keys(schipper.data[0].attributes).forEach(function (key) {
            if (mandatoryFields.indexOf(key) > -1) {
                numberFields++;
                if (schipper[key] !== '') {
                    numberComplete++;
                }
            }
        });
        percentageComplete = (numberComplete / numberFields) * 100;
        localStorage.setItem('userPercentageComplete', parseInt(percentageComplete));
    },
    checkUserComplete: function () {
        let percentage = localStorage.getItem('userPercentageComplete');
        let numberOfShips = localStorage.getItem('userNumberOfShips');
        percentage = parseInt(percentage);
        numberOfShips = parseInt(numberOfShips);

        if (percentage === 100 && numberOfShips > 0) {
            localStorage.setItem('userComplete', true);
        } else {
            localStorage.setItem('userComplete', false);
        }
    },
    getUserInfo: function () {
        let user = {};
        if (localStorage) {
            user.userInfo = localStorage.getItem('userInfo') !== null ? JSON.parse(localStorage.getItem('userInfo')) : '';
            user.complete = localStorage.getItem('userComplete') !== null ? localStorage.getItem('userComplete') : false;
            user.percentageComplete = localStorage.getItem('userPercentageComplete') !== null ? localStorage.getItem('userPercentageComplete') : 0;
            user.numberOfShips = localStorage.getItem('userNumberOfShips') !== null ? localStorage.getItem('userNumberOfShips') : 0;
            return user;
        }
        return false;
    },
    setUserNumberShips: function (numberOfShips) {
        if (localStorage.length > 0) {
            localStorage.setItem('userNumberOfShips', numberOfShips);
        }
    },
    addNotificationMessage: function (type, message) {
        if (localStorage) {
            // let messages = localStorage.getItem('messages') !== null ? JSON.parse(localStorage.getItem('messages')) : [];
            let messages = [];
            let _message = {};
            _message.type = type;
            _message.message = message;
            messages.push(_message);
            localStorage.setItem('messages', JSON.stringify(messages));
        }
    },
    removeNotification: function (index) {
        let messages = [];
        localStorage.setItem('messages', JSON.stringify(messages));
    }
};

export default helper;
