import React, { useState } from "react";
import { usePortVisits } from "../../Hooks/usePortVisits";
import { useTranslation } from "react-i18next";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import nlLocale from "date-fns/locale/nl";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CellBezoek from "./Table/CellBezoek";

export default () => {
    const { visits: portVisits, loading: portVisitsLoading } = usePortVisits();
    const { t } = useTranslation();
    const rows = createRows(portVisits);
    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("bezoeknummer");

    if (portVisitsLoading) return '';

    function createRows(bezoeken) {
        let rows = [];
        bezoeken.forEach(bezoek => {
            bezoek = createData(bezoek);
            rows = rows.concat(bezoek);
        });

        return rows;
    }

    function handleRequestSort(event, property) {
        switch (property) {
            case ('bezoeknummer'):
                property = 'id';
                break;
            case ('schip'):
                property = 'schipname';
                break;
            case ('aankomst-vertrek'):
                property = 'aankomstdatum';
                break;
            case ('haven'):
                property = 'havenname';
                break;
            default:
                break;
        }

        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    const [searchTextQuery, setSearchTextQuery] = React.useState('');
    function search(e) {
        setSearchTextQuery(e.target.value);
    }

    const [selectedFromDate, setSelectedFromDate] = React.useState(new Date(new Date().setFullYear(new Date().getFullYear(), new Date().getMonth() - 24)));
    function searchFromDate(date) {
        setSelectedFromDate(new Date(date));
    }

    const [selectedToDate, setSelectedToDate] = React.useState(new Date(new Date().setFullYear(new Date().getFullYear(), new Date().getMonth() + 12)));
    function searchToDate(date) {
        setSelectedToDate(new Date(date));
    }

    return (
        <section className="data-table">
            <Grid container className="search-table search-container" justifyContent="space-between">
                <TextField
                    id="outlined-search"
                    label={t('algemeen.search-label')}
                    type="search"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    onChange={search}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="filter-from-date"
                        label={t("havenbezoeken.vanafdatum")}
                        value={selectedFromDate}
                        onChange={searchFromDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        format="dd-MM-yyyy"
                    />
                    <KeyboardDatePicker
                        margin="normal"
                        id="filter-to-date"
                        label={t("havenbezoeken.totdatum")}
                        value={selectedToDate}
                        onChange={searchToDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        format="dd-MM-yyyy"
                    />
                </MuiPickersUtilsProvider>
            </Grid>

            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <div className={classes.tableWrapper}>
                        <Table
                            className={classes.table}
                            aria-labelledby={t("havenbezoeken.documentTitle")}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />

                            <TableBody>
                                {stableSort(rows, getSorting(order, orderBy), searchTextQuery, selectedFromDate, selectedToDate).map((row, index) => {
                                    let dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
                                    let timeOptions = { hour: '2-digit', minute: '2-digit' };
                                    const aankomstDatum = new Date(row.attributes.aankomstdatum);
                                    const aankomstTijd = new Date(row.attributes.aankomsttijd);

                                    const vertrekDatum = new Date(row.attributes.vertrekdatum);
                                    const vertrekTijd = new Date(row.attributes.vertrektijd);

                                    let bezoekId = row.id.toString();
                                    bezoekId = bezoekId.padStart(8, '0');

                                    let createdAt = new Date(row.attributes.created_at);
                                    let now = new Date();
                                    let diff = ((Date.parse(now) - Date.parse(createdAt)) / 1000) / 60;
                                    // Fix for non matching server time (2h difference)
                                    diff = diff - 120;

                                    let tableRowClassName = '';
                                    tableRowClassName += diff <= 5 ? ' new' : '';

                                    const renderStatus = (status) => {
                                        let icon = '';
                                        let title = '';
                                        switch (parseInt(status)) {
                                            case 0: // OPEN
                                                icon = 'icon-status-nog-niet-bevestigd';
                                                title = t('havenbezoeken.status.wachten');
                                                break;
                                            case 1: // in POMACS
                                                icon = 'icon-check-circle';
                                                title = t('havenbezoeken.status.ontvangen');
                                                break;
                                            case 2:
                                                icon = 'icon-check-circle';
                                                title = t('havenbezoeken.status.verwerkt');
                                                break;
                                            case 3:
                                                icon = 'icon-check-circle';
                                                title = t('havenbezoeken.status.afgerond');
                                                break;
                                            case 4:
                                                icon = 'icon-status-verzonder-naar-belastingkantoor';
                                                title = t('havenbezoeken.status.verzonden');
                                                break;
                                            case 5:
                                                icon = 'icon-status-verzonder-naar-belastingkantoor';
                                                title = t('havenbezoeken.status.correctieverzonden');
                                                break;
                                            case 6:
                                                icon = 'icon-check-circle';
                                                title = t('havenbezoeken.status.abonnement');
                                                break;
                                            case 7:
                                                icon = 'icon-check-circle';
                                                title = t('havenbezoeken.status.vrijstelling');
                                                break;
                                            default:
                                                icon = 'icon-status-nog-niet-bevestigd';
                                                title = t('havenbezoeken.status.wachten');
                                                break;
                                        }
                                        if (icon !== '' && title !== '') {
                                            return (
                                                <Tooltip title={title}>
                                                    <Icon className={icon} />
                                                </Tooltip>
                                            );
                                        }
                                    };

                                    return (
                                        <TableRow
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id + row.havenname}
                                            className={tableRowClassName}>
                                            <TableCell>
                                                {bezoekId}
                                            </TableCell>
                                            <TableCell>
                                                <div className="date">
                                                    <span className="text">
                                                        <Icon className="icon-aankomst_1" />{aankomstDatum.toLocaleDateString('nl-NL', dateOptions)} {aankomstTijd.toLocaleTimeString('nl-NL', timeOptions)}<br />
                                                        <Icon className="icon-vertrek_11" />{vertrekDatum.toLocaleDateString('nl-NL', dateOptions)} {vertrekTijd.toLocaleTimeString('nl-NL', timeOptions)}
                                                    </span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="haven">
                                                    {row.attributes.haven?.__typename === 'Haven'
                                                        && <>
                                                            <span>{t('havens.' + row.attributes.haven?.name.toLowerCase())}</span>
                                                            <br />
                                                            <span>{row.attributes.havenbedrijf.name}</span>
                                                        </>}
                                                    {row.attributes.haven?.__typename === 'HavenEntity'
                                                        && <>
                                                            {row.havenname !== undefined && <span>{t('havens.' + row.havenname.toLowerCase())}</span>}
                                                            <br />
                                                            <span>{row.attributes.havenbedrijf.attributes?.name}</span>
                                                        </>}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <List className="schepen" disablePadding>
                                                    <ListItem key={'schip-' + row.attributes.schip.id} disableGutters>
                                                        <ListItemText primary={row.schipname} />
                                                    </ListItem>
                                                </List>
                                            </TableCell>
                                            <CellBezoek row={row} />
                                            <TableCell align="right">
                                                {renderStatus(row.attributes.status)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>

                </Paper>
            </div>
        </section>
    );
}

function createData(data) {
    const created = { ...data };
    created.havenname = data.attributes.haven.name !== undefined ? data.attributes.haven.name : data.attributes.haven.attributes.name
    created.schipname = data.attributes.schip.name !== undefined ? data.attributes.schip.name : data.attributes.schip.attributes.name
    return created;
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp, searchText, selectedFromDate, selectedToDate) {
    // Search
    let tempArray = array;
    tempArray.forEach(item => {
        let includeInDateResults = false;
        let includeInTextResults = false;

        // Search Dates
        let fromDate = new Date(item.attributes.aankomstdatum);
        if (!includeInDateResults && fromDate >= selectedFromDate && fromDate <= selectedToDate) {
            includeInDateResults = true;
        }
        // Search Text
        if (searchText.length > 1) {
            if (!includeInTextResults && typeof item.schipname !== 'undefined' && item.schipname.toLowerCase().indexOf(searchText.toString().toLowerCase()) >= 0) {
                includeInTextResults = true;
            }
            if (!includeInTextResults && typeof item.havenname !== 'undefined' && item.havenname.toLowerCase().indexOf(searchText.toString().toLowerCase()) >= 0) {
                includeInTextResults = true;
            }
            if (!includeInTextResults && (item.attributes.havenbedrijf) && typeof item.attributes.havenbedrijf.name !== 'undefined' && item.attributes.havenbedrijf.name.toLowerCase().indexOf(searchText.toString().toLowerCase()) >= 0) {
                includeInTextResults = true;
            }
            let bezoekId = item.id.toString();
            bezoekId = bezoekId.padStart(8, '0');
            if (!includeInTextResults && typeof bezoekId !== 'undefined' && bezoekId.indexOf(searchText.toString()) >= 0) {
                includeInTextResults = true;
            }
            if (!includeInTextResults && typeof item.attributes.schip.eni !== 'undefined' && item.attributes.schip.eni.toString().indexOf(searchText.toString().toLowerCase()) >= 0) {
                includeInTextResults = true;
            }
        } else {
            includeInTextResults = true;
        }

        item.include = includeInDateResults && includeInTextResults;
        if (!item.include) {
            tempArray = arrayRemove(tempArray, item);
        }
    });
    array = tempArray;


    // Order
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
        return ele !== value;
    });

}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: 'bezoeknummer', numeric: false, disablePadding: false, label: 'havenbezoeken.tabel-bezoeknummer' },
    { id: 'aankomst-vertrek', numeric: false, disablePadding: false, label: 'havenbezoeken.tabel-aankomst-vertrek' },
    { id: 'haven', numeric: false, disablePadding: false, label: 'havenbezoeken.tabel-haven' },
    { id: 'schip', numeric: false, disablePadding: false, label: 'havenbezoeken.tabel-schip' },
    { id: 'visit_type', numeric: false, disablePadding: false, label: 'havenbezoeken.tabel-bezoek' },
    { id: 'status', numeric: true, disablePadding: false, label: 'havenbezoeken.tabel-status' },
];

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>

            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {t(row.label)}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 900,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));
