import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";


const portVisitsAdapter = createEntityAdapter();
const portVisitsSlide = createSlice({
    name: 'portVisits',
    initialState: portVisitsAdapter.getInitialState(),
    reducers: {
        setPortVisits: (state, action) => {
            portVisitsAdapter.upsertMany(state, action.payload);
        },
    }
})

export const {setPortVisits} = portVisitsSlide.actions;
export const {selectAll: selectAllPortVisits} = portVisitsAdapter.getSelectors(state => state.portVisits);
export const portVisitsReducer = portVisitsSlide.reducer;