import React from "react";
import Message from "./Message";
import {useNotifications} from "../../Hooks/useNotifications";

export default () => {
    const {notifications, remove: removeNotification} = useNotifications();

    return (
        <>
            {notifications.map((item, index) => {
                return (<Message message={item} key={index} remove={removeNotification} />)
            })}
        </>
    );
}