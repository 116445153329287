import { createAction, createReducer } from '@reduxjs/toolkit'
import {BezoekDefinition} from "../containers/Havenbezoek/BezoekDefinition";

export const updatePortVisit = createAction('portvisit/update');
export const setPortVisitError = createAction('portvisit/seterror');

export const portVisitReducer = createReducer(getPopulatedDefaultState(), (builder) => {
    builder
        .addCase(updatePortVisit, (state, action) => {
            state.visit = action.payload;
            localStorage.setItem('newVisit', JSON.stringify(state));
        })
        .addCase(setPortVisitError, (state, action) => {
            state.errors = action.payload;
            localStorage.setItem('newVisit', JSON.stringify(state));
        })
})

function getPopulatedDefaultState() {
    let cached = localStorage.getItem('newVisit') ? JSON.parse(localStorage.getItem('newVisit')) : {};
    let defaultState = Object.assign(BezoekDefinition, cached);

    defaultState.visit.aankomstdatum = new Date(defaultState.visit.aankomstdatum).toString();
    defaultState.visit.aankomsttijd  = new Date(defaultState.visit.aankomsttijd).toString();
    defaultState.visit.vertrekdatum  = new Date(defaultState.visit.vertrekdatum).toString();
    defaultState.visit.vertrektijd   = new Date(defaultState.visit.vertrektijd).toString();
    defaultState.visit.factuuradres_land = 103;
    
    defaultState.errors.serverError  = false; // Do not cache server errors

    return defaultState;
}