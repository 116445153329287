import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { findIndex, get, map, replace, set } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import helper from "../../assets/helper/";
import TermsContent from './termscontent.js';

// Utils
import logo from '../../assets/img/logo-port-of-twente.svg';
import auth from '../../utils/auth';
import request from '../../utils/request';

import form from './forms.json';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class AuthPage extends React.Component {
    state = { value: {}, errors: [], didCheckErrors: false, errorMessageLogin: '', errorMessageForgotPassword: '', errorMessageRegister: '', language: 'nl', schipperRequest: false, schepenRequest: false };

    constructor (props) {
        super(props);
        document.title = this.props.t('inloggen.documentTitle');

        this.state = {
            openForgotPassword: false,
            openResetPassword: false,
            openRegister: false,
            openTerms: false,
            acceptedTerms: false,
            language: props.i18n.language,
            message: ''
        };
        this.handleClickOpenForgotPassword = this.handleClickOpenForgotPassword.bind(this);
        this.handleCloseForgotPassword = this.handleCloseForgotPassword.bind(this);
        this.handleOpenResetPassword = this.handleOpenResetPassword.bind(this);
        this.handleCloseResetPassword = this.handleCloseResetPassword.bind(this);
        this.handleClickOpenRegister = this.handleClickOpenRegister.bind(this);
        this.handleCloseRegister = this.handleCloseRegister.bind(this);
        this.handleClickOpenTerms = this.handleClickOpenTerms.bind(this);
        this.handleCloseTerms = this.handleCloseTerms.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    }

    componentDidMount() {
        // /auth/reset-password afvangen
        if (this.props.location.pathname === "/auth/reset-password") {
            this.handleOpenResetPassword();
        }

        // If logged in, redirect
        let userInfo = auth.getUserInfo();
        if (userInfo) {
            this.props.history.push('/');
        }
        this.generateForm(this.props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.authType !== this.props.match.params.authType) {
            this.generateForm(this.props);
        }
    }

    getRequestURL = () => {
        let requestURL;

        switch (this.props.match.params.authType) {
            case 'login':
                requestURL = process.env.REACT_APP_STRAPI_URL + '/auth/local';
                break;
            case 'register':
                requestURL = process.env.REACT_APP_STRAPI_URL + '/auth/local/register';
                break;
            case 'reset-password':
                requestURL = process.env.REACT_APP_STRAPI_URL + '/auth/reset-password';
                break;
            case 'forgot-password':
                requestURL = process.env.REACT_APP_STRAPI_URL + '/auth/forgot-password';
                break;
            default:
        }

        return requestURL;
    };

    generateForm = props => {
        const params = props.location.search
            ? replace(props.location.search, '?code=', '')
            : props.match.params.id;
        this.setForm(props.match.params.authType, params);
    };

    handleChange = ({ target }) =>
        this.setState({
            value: { ...this.state.value, [target.name]: target.value },
        });

    handleChangeCheckbox = name => event => {
        this.setState({ ...this.state, [name]: event.target.checked });
    };

    handleChangeLanguage = ({ target }) => {
        this.setState({ language: target.value });
        this.props.onLanguageChange(target.value);
    };

    string_to_slug = (str) => {
        console.log('str = ', str);
        str = str.replace(/^\s+|\s+$/g, ""); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
        const to = "aaaaaaeeeeiiiioooouuuunc------";

        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
        }

        str = str
            .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
            .replace(/\s+/g, "-") // collapse whitespace and replace by -
            .replace(/-+/g, "-") // collapse dashes
            .replace(/^-+/, "") // trim - from start of text
            .replace(/-+$/, ""); // trim - from end of text

        return str;
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const body = this.state.value;
        const requestURL = this.getRequestURL();
        const schipperURL = process.env.REACT_APP_STRAPI_URL + '/schippers/';
        let schepenURL = process.env.REACT_APP_STRAPI_URL + '/users/me?populate=schips';

        if (this.props.match.params.authType !== 'forgot-password') {
            if (body.password.length < 5) {
                this.setState({ message: this.props.t('inloggen.wachtwoordlengte') });
                window.scrollTo(0, 0);
                return;
            }
        }

        // This line is required for the callback url to redirect your user to app
        if (this.props.match.params.authType === 'forgot-password') {
            delete body.value
            delete body.identifier
            delete body.password
        }

        if (this.props.match.params.authType === 'register') {
            body.username = body.email;
        }

        if (this.props.match.params.authType === 'reset-password') {
            delete body.value;
            delete body.identifier;
        }

        request(requestURL, { method: 'POST', body: body })
            .then(response => {
                if (typeof body.rememberMe === 'undefined') {
                    body.rememberMe = true;
                }
                auth.setToken(response.jwt, body.rememberMe);
                auth.setUserInfo(response.user, body.rememberMe);

                if (this.props.match.params.authType === 'login') {
                    // Haal gegevens schipper op
                    const promiseSchipper = new Promise((resolve, reject) => {
                        request(schipperURL, { method: 'GET' })
                            .then(schipperResponse => {
                                console.log(schipperResponse);
                                helper.setUserInfo(schipperResponse);
                                if (schipperResponse.taal) {
                                    this.props.onLanguageChange(schipperResponse.taal)
                                }
                                resolve(schipperResponse)
                            })
                            .catch(schipperErr => {
                                console.log('schipperError')
                                console.log(schipperErr)
                                reject(new Error('schipperError'))
                            });
                    });
                    // Haal gegevens schepen op
                    const promiseSchepen = new Promise((resolve, reject) => {
                        request(schepenURL, { method: 'GET' })
                            .then(responseSchepen => {
                                helper.setUserNumberShips(Object.keys(responseSchepen.schips).length)
                                resolve(responseSchepen)
                            })
                            .catch(schepenErr => {
                                console.log('schepenError')
                                console.log(schepenErr)
                                resolve()
                            });
                    });

                    //Als beide gegevens opgehaald zijn, redirect
                    Promise.all([promiseSchipper, promiseSchepen]).then((values) => {
                        this.setState({ schipperRequest: true });
                        this.setState({ schepenRequest: true });
                        helper.checkUserComplete();
                        this.redirectUser();
                    });

                } else if (this.props.match.params.authType === 'forgot-password') {
                    this.handleCloseForgotPassword();
                    this.setState({ message: this.props.t('inloggen.info.wachtwoord-vergeten') });
                } else if (this.props.match.params.authType === 'register') {
                    this.redirectUser();
                } else if (this.props.match.params.authType === 'reset-password') {
                    this.redirectUser();
                }

            })
            .catch((loginError) => {
                console.log('loginError ', loginError.response);
                switch (this.props.match.params.authType) {
                    case 'login':
                        this.setState({
                            errorMessageLogin: this.props.t('inloggen.' + this.string_to_slug(loginError.response.payload.error.message))
                        });
                        break;
                    case 'register':
                        this.setState({
                            errorMessageRegister: this.props.t('inloggen.' + this.string_to_slug(loginError.response.payload.error.message))
                        });
                        break;
                    case 'forgot-password':
                        this.setState({
                            errorMessageForgotPassword: (loginError.response.status === 500) ?
                                loginError.response.payload.message :
                                this.props.t('inloggen.' + this.string_to_slug(loginError.response.payload.error.message))
                        });
                        break;
                    case 'reset-password':
                        this.setState({
                            errorMessageForgotPassword: (loginError.response.status === 500) ?
                                loginError.response.payload.message :
                                this.props.t('inloggen.' + this.string_to_slug(loginError.response.payload.error.message))
                        });
                        break;
                    default:
                        break;
                }
            });
    };

    redirectUser = () => {
        window.location.href = '/';
    };

    /**
     * Function that allows to set the value to be modified
     * @param {String} formType the auth view type ex: login
     * @param {String} email    Optional
     */
    setForm = (formType, email) => {
        const value = get(form, ['data', formType], {});

        if (formType === 'reset-password') {
            set(value, 'code', email);
        }
        this.setState({ value });
    };

    /**
     * Check the URL's params to render the appropriate links
     * @return {Element} Returns navigation links
     */
    renderLink = () => {
        if (this.props.match.params.authType === 'login') {
            return (
                <div>
                    <Link to="/" onClick={this.handleClickOpen}>{this.props.t('inloggen.link-wachtwoord-vergeten')}</Link>
                    &nbsp;<span className="devider-text">{this.props.t('algemeen.devider-text-or')}</span>&nbsp;
                    <Link to="/" onClick={this.handleClickOpen}>{this.props.t('inloggen.link-registreren')}</Link>
                </div>
            );
        }

        return (
            <div>
                <Link to="/auth/login">{this.props.t('inloggen.link-inloggen')}</Link>
            </div>
        );
    };

    handleClickOpenForgotPassword() {
        this.props.match.params.authType = 'forgot-password';
        this.setState({
            openForgotPassword: true
        });
    }

    handleCloseForgotPassword() {
        this.props.match.params.authType = 'login';
        this.setState({
            openForgotPassword: false,
            errorMessageForgotPassword: ''
        });
    }

    handleOpenResetPassword() {
        this.props.match.params.authType = 'reset-password';
        this.setState({
            openResetPassword: true
        });
    }

    handleCloseResetPassword() {
        this.props.match.params.authType = 'login';
        this.setState({
            openResetPassword: false,
            errorMessageResetPassword: ''
        });
    }

    handleClickOpenRegister() {
        this.props.match.params.authType = 'register';
        this.setState({
            openRegister: true
        });
    }

    handleCloseRegister() {
        this.props.match.params.authType = 'login';
        this.setState({
            openRegister: false
        });
    }

    handleClickOpenTerms() {
        this.setState({
            openTerms: true
        });
    }

    handleCloseTerms() {
        this.setState({
            openTerms: false
        });
    }

    render() {
        //redirect nadat aanvragen van Schipper en Schepen informatie gedaan zijn
        //overbodig omdat regel 221 ook al redirect?
        if (this.state.schipperRequest && this.state.schepenRequest) {
            return window.location.href = '/';
        }

        const classes = makeStyles(theme => ({
            '@global': {
                body: {
                    backgroundColor: theme.palette.common.white,
                },
            },
            card: {
                minWidth: 275,
            },
            form: {
                width: '100%', // Fix IE 11 issue.
                marginTop: theme.spacing(3),
            },
            submit: {
                margin: theme.spacing(3, 0, 2),
            },
        }));
        //const loginInputs = get(form, ['views', this.props.match.params.authType], []);
        const loginInputs = get(form, ['views', 'login'], []);
        const forgotPasswordInputs = get(form, ['views', 'forgot-password'], []);
        const resetPasswordInputs = get(form, ['views', 'reset-password'], []);
        const registerInputs = get(form, ['views', 'register'], []);

        const languages = [
            {
                value: 'nl',
                label: 'Nederlands',
            },
            {
                value: 'en',
                label: 'English',
            },
            {
                value: 'de',
                label: 'Deutsch',
            },
            {
                value: 'fr',
                label: 'Français',
            },
        ];

        return (
            <Grid container>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="login-page">
                    <Container
                        className="login-card"
                        maxWidth="sm">
                        <CssBaseline />
                        <Card className={classes.card}>
                            <CardContent className="card-content">
                                <Grid container className="header" direction="row" alignItems="center">
                                    <img src={logo} alt="Logo port of Twente" />
                                </Grid>
                                <h1>{this.props.t('inloggen.title')}</h1>
                                <p>{this.props.t('inloggen.intro')}</p>
                                <form className={classes.form} onSubmit={this.handleSubmit}>
                                    <Grid container spacing={2}>
                                        {this.state.message &&
                                            <Grid item xs={12}>
                                                <Alert severity="warning">
                                                    {this.state.message}
                                                </Alert>
                                            </Grid>
                                        }
                                        {this.state.errorMessageLogin &&
                                            <Grid item xs={12}>
                                                <Alert severity="warning">
                                                    {this.state.errorMessageLogin}
                                                </Alert>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <TextField
                                                select
                                                id="outlined-select-language"
                                                className={'language-select active-language-' + this.state.language}
                                                label={this.props.t('algemeen.language')}
                                                value={this.state.language}
                                                onChange={this.handleChangeLanguage}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                            >
                                                {languages.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {map(loginInputs, (input, key) => (
                                            <Grid item xs={12} key={get(input, 'name')}>
                                                <TextField
                                                    autoFocus={key === 0}
                                                    errors={get(
                                                        this.state.errors,
                                                        [
                                                            findIndex(this.state.errors, ['name', input.name]),
                                                            'errors',
                                                        ],
                                                        []
                                                    )}
                                                    label={this.props.t(get(input, 'label'))}
                                                    name={get(input, 'name')}
                                                    className={'field-' + get(input, 'type') + ' field-' + get(input, 'type') + '-' + get(input, 'name')}
                                                    onChange={this.handleChange}
                                                    placeholder={get(input, 'placeholder')}
                                                    type={get(input, 'type')}
                                                    validations={{ required: true }}
                                                    value={get(this.state.value, get(input, 'name'), '')}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                />
                                            </Grid>
                                        ))}
                                        <Grid item xs={12}>
                                            <Box>
                                                <Button
                                                    label="Submit"
                                                    primary="true"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                >{this.props.t('inloggen.btn-inloggen')}</Button>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Grid container className="auth-actions-controls">
                                                <div className="forgot-password">
                                                    <Link to="/" onClick={this.handleClickOpenForgotPassword}>{this.props.t('inloggen.link-wachtwoord-vergeten')}</Link>
                                                </div>

                                                <div className="register">
                                                    <Link to="/" onClick={this.handleClickOpenRegister}>{this.props.t('inloggen.link-registreren')}</Link>
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </form>


                                <Grid container className="auth-actions">
                                    <div className="forgot-password">
                                        <Dialog open={this.state.openForgotPassword} onClose={this.handleCloseForgotPassword} aria-labelledby="form-dialog-forgot-password" className="dialog">
                                            <DialogTitle id="form-dialog-forgot-password" className="dialog-title">{this.props.t('inloggen.forgot-password-title')}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    <span>{this.props.t('inloggen.forgot-password-description')}</span>
                                                </DialogContentText>
                                                <form className={classes.form} onSubmit={this.handleSubmit}>
                                                    <Grid container spacing={2}>
                                                        {this.state.errorMessageForgotPassword &&
                                                            <Grid item xs={12}>
                                                                <Alert severity="warning">
                                                                    {this.state.errorMessageForgotPassword}
                                                                </Alert>
                                                            </Grid>
                                                        }
                                                        {map(forgotPasswordInputs, (input, key) => (
                                                            <Grid item xs={12} key={get(input, 'name')}>
                                                                <TextField
                                                                    autoFocus={key === 0}
                                                                    errors={get(
                                                                        this.state.errors,
                                                                        [
                                                                            findIndex(this.state.errors, ['name', input.name]),
                                                                            'errors',
                                                                        ],
                                                                        []
                                                                    )}
                                                                    label={this.props.t(get(input, 'label'))}
                                                                    name={get(input, 'name')}
                                                                    className={'field-' + get(input, 'type') + ' field-' + get(input, 'type') + '-' + get(input, 'name')}
                                                                    onChange={this.handleChange}
                                                                    placeholder={get(input, 'placeholder')}
                                                                    type={get(input, 'type')}
                                                                    validations={{ required: true }}
                                                                    value={get(this.state.value, get(input, 'name'), '')}
                                                                    variant="outlined"
                                                                    error={this.state.errorMessageForgotPassword}
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        ))}
                                                        <Grid item xs={12}>
                                                            <Box>
                                                                <Button
                                                                    label="Submit"
                                                                    primary="true"
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.submit}
                                                                >{this.props.t('inloggen.submit')}</Button>
                                                                <Button
                                                                    onClick={this.handleCloseForgotPassword}
                                                                    color="primary"
                                                                    variant="outlined">
                                                                    {this.props.t('algemeen.close')}
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div className="linkContainer">{this.props.renderLink}</div>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </DialogContent>
                                        </Dialog>
                                    </div>

                                    <div className="reset-password">
                                        <Dialog open={this.state.openResetPassword} onClose={this.handleCloseResetPassword} aria-labelledby="form-dialog-reset-password" className="dialog">
                                            <DialogTitle id="form-dialog-reset-password" className="dialog-title">{this.props.t('inloggen.reset-password-title')}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    <span>{this.props.t('inloggen.reset-password-description')}</span>
                                                </DialogContentText>
                                                <form className={classes.form} onSubmit={this.handleSubmit}>
                                                    <Grid container spacing={2}>
                                                        {this.state.errorMessageResetPassword &&
                                                            <Grid item xs={12}>
                                                                <Alert severity="warning">
                                                                    {this.state.errorMessageResetPassword}
                                                                </Alert>
                                                            </Grid>
                                                        }
                                                        {map(resetPasswordInputs, (input, key) => (
                                                            <Grid item xs={12} key={get(input, 'name')}>
                                                                <TextField
                                                                    autoFocus={key === 0}
                                                                    errors={get(
                                                                        this.state.errors,
                                                                        [
                                                                            findIndex(this.state.errors, ['name', input.name]),
                                                                            'errors',
                                                                        ],
                                                                        []
                                                                    )}
                                                                    label={this.props.t(get(input, 'label'))}
                                                                    name={get(input, 'name')}
                                                                    className={'field-' + get(input, 'type') + ' field-' + get(input, 'type') + '-' + get(input, 'name')}
                                                                    onChange={this.handleChange}
                                                                    placeholder={get(input, 'placeholder')}
                                                                    type={get(input, 'type')}
                                                                    validations={{ required: true }}
                                                                    value={get(this.state.value, get(input, 'name'), '')}
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                    error={((get(input, 'name') === "password") && (get(this.state.value, get(input, 'name'), '').length < 5))}
                                                                    helperText={((get(input, 'name') === "password") && (get(this.state.value, get(input, 'name'), '').length < 5)) ? this.props.t('inloggen.password-strength') : ""}
                                                                />
                                                            </Grid>
                                                        ))}

                                                        <Grid item xs={12}>
                                                            <Box>
                                                                <Button
                                                                    label="Submit"
                                                                    primary="true"
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.submit}
                                                                >{this.props.t('inloggen.submit')}</Button>
                                                                <Button
                                                                    onClick={this.handleCloseResetPassword}
                                                                    color="primary"
                                                                    variant="outlined">
                                                                    {this.props.t('algemeen.close')}
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div className="linkContainer">{this.props.renderLink}</div>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </DialogContent>
                                        </Dialog>
                                    </div>

                                    <div className="register">
                                        <Dialog open={this.state.openRegister} onClose={this.handleCloseRegister} aria-labelledby="form-dialog-register" className="dialog">
                                            <DialogTitle id="form-dialog-register" className="dialog-title">{this.props.t('inloggen.register-title')}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    <span>{this.props.t('inloggen.register-description')}</span>
                                                </DialogContentText>
                                                <form className={classes.form} onSubmit={this.handleSubmit}>
                                                    <Grid container spacing={2}>
                                                        {this.state.errorMessageRegister &&
                                                            <Grid item xs={12}>
                                                                <Alert severity="warning">
                                                                    {this.state.errorMessageRegister}
                                                                </Alert>
                                                            </Grid>
                                                        }
                                                        {map(registerInputs, (input, key) => (
                                                            <Grid item xs={12} key={get(input, 'name')}>
                                                                <TextField
                                                                    autoFocus={key === 0}
                                                                    errors={get(
                                                                        this.state.errors,
                                                                        [
                                                                            findIndex(this.state.errors, ['name', input.name]),
                                                                            'errors',
                                                                        ],
                                                                        []
                                                                    )}
                                                                    label={this.props.t(get(input, 'label'))}
                                                                    name={get(input, 'name')}
                                                                    className={'field-' + get(input, 'type') + ' field-' + get(input, 'type') + '-' + get(input, 'name')}
                                                                    onChange={this.handleChange}
                                                                    placeholder={get(input, 'placeholder')}
                                                                    type={get(input, 'type')}
                                                                    validations={{ required: true }}
                                                                    value={get(this.state.value, get(input, 'name'), '')}
                                                                    variant="outlined"
                                                                    required
                                                                    fullWidth
                                                                    error={((get(input, 'name') === "password") && (get(this.state.value, get(input, 'name'), '').length < 5))}
                                                                    helperText={((get(input, 'name') === "password") && (get(this.state.value, get(input, 'name'), '').length < 5)) ? this.props.t('inloggen.password-strength') : ""}
                                                                />
                                                            </Grid>
                                                        ))}

                                                        <Grid item xs={12} className="accept-terms">
                                                            <FormGroup>
                                                                <Grid
                                                                    container
                                                                    alignItems="center">
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={this.state.acceptedTerms}
                                                                                onChange={this.handleChangeCheckbox('acceptedTerms')}
                                                                                value="acceptedTerms"
                                                                                color="primary"
                                                                                required
                                                                            />
                                                                        }
                                                                        label={this.props.t('inloggen.i-accept-the')}
                                                                    />
                                                                    <Link to="/" onClick={this.handleClickOpenTerms}>{this.props.t('inloggen.terms')}</Link>
                                                                </Grid>
                                                            </FormGroup>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Box>
                                                                <Button
                                                                    label="Submit"
                                                                    primary="true"
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.submit}
                                                                >{this.props.t('inloggen.submit')}</Button>
                                                                <Button
                                                                    onClick={this.handleCloseRegister}
                                                                    color="primary"
                                                                    variant="outlined">
                                                                    {this.props.t('algemeen.close')}
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div className="linkContainer">{this.props.renderLink}</div>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            </DialogContent>
                                        </Dialog>
                                    </div>

                                    <div className="terms">
                                        <Dialog open={this.state.openTerms} onClose={this.handleCloseTerms} aria-labelledby="form-dialog-terms" className="dialog" maxWidth="md">
                                            <DialogTitle id="form-dialog-terms" className="dialog-title">{this.props.t('inloggen.terms-title')}</DialogTitle>
                                            <DialogContent>
                                                <TermsContent />
                                            </DialogContent>
                                            <DialogActions>
                                                <Grid item xs={12}>
                                                    <Box>
                                                        <Button
                                                            onClick={this.handleCloseTerms}
                                                            color="primary"
                                                            variant="outlined">
                                                            {this.props.t('algemeen.close')}
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                </Grid>
                                <br></br>
                                <i className="disclaimer">{this.props.t('inloggen.disclaimer.deel-een')}
                                    <Link className="disclaimer" href="tel:+31 74-2912286">
                                        {this.props.t('inloggen.disclaimer.telefoon')}
                                    </Link>
                                    {this.props.t('inloggen.disclaimer.deel-twee')}
                                    <Link className="disclaimer" href="mailto:havenmeesters@portoftwente.com">
                                        {this.props.t('inloggen.disclaimer.email')}
                                    </Link>
                                </i>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
        );
    }
}

AuthPage.defaultProps = {};
AuthPage.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default withTranslation()(AuthPage);
