import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default function TermsContent(props) {
    const { t } = useTranslation();

    return (
        <section className="terms-content">
            <Grid item className="article">
                <Grid item xs={12}>
                    <Typography variant="h6">{t('algemene-voorwaarden.artikel-1.titel')}</Typography>
                </Grid>
                <Grid container className="item">
                    <Grid container className="item">
                        <Grid item xs={3}>
                            <Typography>{t('algemene-voorwaarden.artikel-1.binnenhavengebied.titel')}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{t('algemene-voorwaarden.artikel-1.binnenhavengebied.text')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.gbt.titel')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.gbt.text')}</Typography>
                    </Grid>
                </Grid>
                <Grid container className="item">
                    <Grid item xs={3}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.gebruiker.titel')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.gebruiker.text')}</Typography>
                    </Grid>
                </Grid>
                <Grid container className="item">
                    <Grid item xs={3}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.havenbedrijf-twente.titel')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.havenbedrijf-twente.text')}</Typography>
                    </Grid>
                </Grid>
                <Grid container className="item">
                    <Grid item xs={3}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.vaartuig.titel')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.vaartuig.text')}</Typography>
                    </Grid>
                </Grid>
                <Grid container className="item">
                    <Grid item xs={3}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.vergoeding.titel')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.vergoeding.text')}</Typography>
                    </Grid>
                </Grid>
                <Grid container className="item">
                    <Grid item xs={3}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.webportaal.titel')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{t('algemene-voorwaarden.artikel-1.webportaal.text')}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item className="article">
                <Grid item xs={12}>
                    <Typography variant="h6">{t('algemene-voorwaarden.artikel-2.titel')}</Typography>
                </Grid>
                <List disablePadding className="dense">
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('1.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-2.1')}</ListItemText>
                    </ListItem>
                </List>
            </Grid>

            <Grid item className="article">
                <Grid item xs={12}>
                    <Typography variant="h6">{t('algemene-voorwaarden.artikel-3.titel')}</Typography>
                </Grid>
                <List disablePadding className="dense">
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('1.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-3.1')}</ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('2.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-3.2')}</ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('3.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-3.3')}</ListItemText>
                    </ListItem>
                    <div className="subitems">
                        <ListItem>
                            <ListItemIcon>{t('a.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.3a')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('b.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.3b')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('c.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.3c')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('d.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.3d')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('e.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.3e')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('f.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.3f')}</ListItemText>
                        </ListItem>
                    </div>
                    <ListItem>
                        <ListItemIcon>{t('4.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-3.4')}</ListItemText>
                    </ListItem>
                    <div className="subitems">
                        <ListItem>
                            <ListItemIcon>{t('a.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.4a')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('b.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.4b')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('c.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.4c')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('d.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.4d')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('e.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.4e')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('f.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.4f')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('g.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.4g')}</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>{t('h.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.4h')}</ListItemText>
                        </ListItem>
                        {t('algemene-voorwaarden.artikel-3.4i').length > 0 &&
                        <ListItem>
                            <ListItemIcon>{t('i.')}</ListItemIcon>
                            <ListItemText>{t('algemene-voorwaarden.artikel-3.4i')}</ListItemText>
                        </ListItem>
                        }
                    </div>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('5.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-3.5')}</ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('6.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-3.6')}</ListItemText>
                    </ListItem>
                </List>
            </Grid>

            <Grid item className="article">
                <Grid item xs={12}>
                    <Typography variant="h6">{t('algemene-voorwaarden.artikel-4.titel')}</Typography>
                </Grid>
                <List disablePadding className="dense">
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('1.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-4.1')}</ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('2.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-4.2')}</ListItemText>
                    </ListItem>
                    {t('algemene-voorwaarden.artikel-4.3').length > 0 &&
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('3.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-4.3')}</ListItemText>
                    </ListItem>
                    }
                </List>
            </Grid>

            <Grid item className="article">
                <Grid item xs={12}>
                    <Typography variant="h6">{t('algemene-voorwaarden.artikel-5.titel')}</Typography>
                </Grid>
                <List disablePadding className="dense">
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('1.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-5.1')}</ListItemText>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('2.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-5.2')}</ListItemText>
                    </ListItem>
                    {t('algemene-voorwaarden.artikel-5.3').length > 0 &&
                    <ListItem alignItems="flex-start">
                        <ListItemIcon>{t('3.')}</ListItemIcon>
                        <ListItemText>{t('algemene-voorwaarden.artikel-5.3')}</ListItemText>
                    </ListItem>
                    }
                </List>
            </Grid>
        </section>
    );
}