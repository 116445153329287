/**
 *
 * PrivateRoute
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your container
 */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {useUser} from "../../Hooks/useUser";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const {isLoggedIn} = useUser()

    return(
        <Route
            {...rest}
            render={props =>
                (isLoggedIn()) ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: 'auth/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    )
};

export default PrivateRoute;
