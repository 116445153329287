import React from 'react';
import {Link} from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

// Utils
import auth from '../../utils/auth';

export default function UserMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();

    function logOut() {
        auth.clearUserInfo();
        auth.clearToken();
        auth.clearAppStorage();
        localStorage.clear();
    }

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div className="usermenu-container">
            <IconButton aria-controls="user-menu" aria-haspopup="true" onClick={handleClick} className="usermenu-drowndown-icon">
                <Icon className="icon icon-user-circle" /><ArrowDropDownIcon/>
            </IconButton>
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem><Link to={'/profiel'} onClick={handleClose}><Icon className="icon-user" />{t('profiel.my-data')}</Link></MenuItem>
                <MenuItem><Link to={'/'} onClick={logOut}><Icon className="icon-logout" />{t('profiel.uitloggen')}</Link></MenuItem>
            </Menu>
        </div>
    );
}