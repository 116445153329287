import {createAction, createReducer} from '@reduxjs/toolkit'
import { v4 as uid } from 'uuid';

export const addNotification = createAction('notifications/add');
export const removeNotification = createAction('notifications/remove');

export const notificationsReducer = createReducer(getPopulatedDefaultState(), (builder) => {
    builder
        .addCase(addNotification, (state, action) => {
            const notification = action.payload;
            notification.id = uid();
            notification.duration = (notification.duration !== undefined) ? notification.duration : 10000;
            state.queue.push(notification);
            localStorage.setItem('notifications', JSON.stringify(state.queue));
        })
        .addCase(removeNotification, (state, action) => {
            const queue = state.queue.filter((item) => {
                return (item.id !== action.payload.id);
            })

            state.queue = queue;
            localStorage.setItem('notifications', JSON.stringify(queue));
        })
})

function getPopulatedDefaultState() {
    return {
        queue: localStorage.getItem('notifications') ? JSON.parse(localStorage.getItem('notifications')) : [],
    };
}