import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import request from "../utils/request";

export const fetchUserId = createAsyncThunk(
    'users/fetchUserId',
    async ( thunkAPI) => {
        const userRequest = process.env.REACT_APP_STRAPI_URL + '/users/me?populate=*';

        return await request(userRequest, {method: 'GET'});
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userId: null,
        schipperId: null,
        loading: false,
        initialized: false,
        isComplete: false,
        data: null,
    },
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
            state.initialized = true;
        },
        setUser(state, action) {
            state.loading = false;
            state.isComplete = true;
            state.userId = action.payload.attributes.user.id;
            state.schipperId = action.payload.id;
            state.data = action.payload.attributes;
        },
    },
    extraReducers: {
        [fetchUserId.pending]: (state, action) => {
            state.userIdPending = true;
            return state;
        },
        [fetchUserId.fulfilled]: (state, action) => {
            state.userId = action.payload.id;
            state.data = {
                email: action.payload.email,
                user: {
                    id: action.payload.id,
                    email: action.payload.email,
                }
            }
            state.loading = false;
        }
    }
})

export const {setLoading, setUser} = userSlice.actions;
export const userReducer = userSlice.reducer;
