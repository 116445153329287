import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { useCountries } from "../../Hooks/useCountries";

const Address = ({ values, onChange, validateVatNumber, formControl }) => {
    const { t } = useTranslation();
    const { countries } = useCountries();
    const { addressFormControl: control, addressFormValidate: validate } = formControl;
    const { validateVat, vatNumberValid, vatError } = validateVatNumber;


    const handleChange = (e) => {
        const value = control(e.target.value, e.target.name);
        validate(value, e.target.name);
        onChange(value, e.target.name);
    }

    return (
        <div className="afwijkend factuuradres">
            <h2>{t('profiel.title-adres-factuur')}
                <Tooltip title={t('schip.info.factuuradres')} className="profile-factuuradres-tooltip">
                    <Icon className="icon-info" />
                </Tooltip>
            </h2>

            <FormControl fullWidth required variant="outlined">
                <TextField
                    id="factuur_bedrijfsnaam"
                    label={t('profiel.bedrijfsnaam')}
                    type="text"
                    name="factuur_bedrijfsnaam"
                    onChange={handleChange}
                    value={values.factuur_bedrijfsnaam}
                    margin="normal"
                    variant="outlined"
                    required
                />
            </FormControl>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="factuur_adres"
                            label={t('profiel.adres')}
                            type="text"
                            name="factuur_adres"
                            onChange={handleChange}
                            value={values.factuur_adres}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                    </FormControl>
                </Grid>
                {parseInt(values.factuur_land) !== 103 &&
                    <Grid item xs={4}>
                        <FormControl fullWidth required variant="outlined">
                            <TextField
                                id="factuur_huisnr"
                                label={t('profiel.huisnr')}
                                type="number"
                                name="factuur_huisnr"
                                onChange={handleChange}
                                value={values.factuur_huisnr}
                                margin="normal"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                }
                {parseInt(values.factuur_land) !== 103 &&
                    <Grid item xs={4}>
                        <FormControl fullWidth required variant="outlined">
                            <TextField
                                id="factuur_huisnrtoevoeging"
                                label={t('profiel.huisnrtoevoeging')}
                                type="text"
                                name="factuur_huisnrtoevoeging"
                                onChange={handleChange}
                                value={values.factuur_huisnrtoevoeging}
                                margin="normal"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                }
                <Grid item xs={4}>
                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="factuur_postcode"
                            label={t('profiel.postcode')}
                            type="text"
                            name="factuur_postcode"
                            onChange={handleChange}
                            value={values.factuur_postcode}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="factuur_plaats"
                            label={t('profiel.plaats')}
                            type="text"
                            name="factuur_plaats"
                            onChange={handleChange}
                            value={values.factuur_plaats}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField
                            select
                            id="factuur_land"
                            name="factuur_land"
                            className={'factuur_land'}
                            label={t('profiel.land')}
                            value={values.factuur_land === '' ? 103 : values.factuur_land}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                        >
                            {countries.map(land => (
                                <MenuItem key={land.attributes.landcode} value={land.attributes.landcode}>
                                    {land.attributes.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
            </Grid>
            <FormControl fullWidth required variant="outlined">
                <TextField
                    id="factuur_kvk"
                    label={t('profiel.kvk')}
                    type="number"
                    name="factuur_kvk"
                    onChange={handleChange}
                    value={values.factuur_kvk}
                    margin="normal"
                    variant="outlined"
                    required={values.factuur_land === '103'}
                />
            </FormControl>

            <FormControl fullWidth required variant="outlined">
                <TextField
                    id="factuur_btw"
                    label={t('profiel.btw')}
                    type="text"
                    name="factuur_btw"
                    onChange={e => {
                        validateVat(e.target.value, values.factuur_land);
                        handleChange(e);
                    }}
                    value={values.factuur_btw}
                    margin="normal"
                    variant="outlined"
                    error={!vatNumberValid}
                    required={values.factuur_land !== '103'}
                />
                {!vatNumberValid && <span className="error">{vatError}</span>}
            </FormControl>
        </div>
    )
}
export default Address;
