import {useQuery} from "@apollo/client";
import {GET_PORTS} from "../Model/Queries";

export function usePorts() {
    const {data, error, loading} = useQuery(
        GET_PORTS
    );

    let ports = (!loading && (error === undefined)) ? data.havens : [];
    let hasError = (error !== undefined);

    return {ports, loading, hasError, error}
}