import { combineReducers } from 'redux'
import {portVisitReducer} from "./portVisitReducer";
import {userReducer} from "./userReducer";
import {notificationsReducer} from "./notificationsReducer";
import {shipsReducer} from "./shipsReducer";
import {portVisitsReducer} from "./portVisitsReducer";

export default combineReducers({
    user: userReducer,
    portVisits: portVisitsReducer,
    portVisit: portVisitReducer,
    ships: shipsReducer,
    notifications: notificationsReducer,
});