
const useFormUtils = () => {
    return {
        formatAlphaNumericOnly: (text) => {
            const textOnlyRegex = /[^a-z0-9]/gi;
            text = text.toString();
            return text.replace(textOnlyRegex, '');
        },
        filterDecimals: (text) => {
            return text.replace(/([^\d]*)/g, "");
        },
    }
}
export default useFormUtils;