import React from 'react';
import { useState } from 'react';
import {Link} from 'react-router-dom'
import HelpIcon from '@material-ui/icons/Help';
import SupportContent from './supportcontent.js';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { useHelpPage } from '../../Hooks/useHelpPage';

export default function Support() {
    const [openSupport, setOpenSupport] = useState(false);
    const {helpPage} = useHelpPage();
      

    function handleClickOpenSupport() {
        setOpenSupport(true);
    }

    function handleCloseSupport() {
        setOpenSupport(false);
    }

    return (
        <div className="support">
            <Link to="/" onClick={handleClickOpenSupport}><HelpIcon className="icon" />{'Hulp nodig?'}</Link>

            <Dialog open={openSupport} onClose={handleCloseSupport} aria-labelledby="form-dialog-terms" className="dialog" maxWidth="md">
                <DialogTitle id="form-dialog-terms" className="dialog-title">{helpPage.Title}</DialogTitle>
                <DialogContent>
                    <SupportContent/>
                </DialogContent>
                <DialogActions>
                    <Grid item xs={12}>
                        <Box>
                            <Button
                                onClick={handleCloseSupport}
                                color="primary"
                                variant="outlined">
                                {"Sluiten"}
                            </Button>
                        </Box>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}