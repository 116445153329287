import React from "react";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import auth from "../utils/auth";

export default (props) => {
    const httpLink = createHttpLink({
        uri: process.env.REACT_APP_STRAPI_GRAPHQL_URL + '/graphql',
    });

    const authLink = setContext((_, { headers }) => {
        const token = auth.getToken();
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    });

    return (
        <ApolloProvider client={client}>
            {props.children}
        </ApolloProvider>
    );
}
