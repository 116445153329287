import {useSelector} from "react-redux";
import {selectShipById} from "../../Reducers/shipsReducer";
import {useDeleteShip} from "./useDeleteShip";

/**
 * @param {Number} id
 * @param {{delete?: {afterDelete?: function(): void}}} options
 * @returns {{isDeleteModalVisible: *, deleteShipProps: {reference: React.MutableRefObject<null>, closeCallback: (function(): void), className: *, title: null, loading: boolean, showModal: *, defaultStyles: boolean}, ship: unknown, DeleteShip: ((function(*): *)|*), deleteShip: deleteShip, loading: *, deleteLoading: boolean}}
 */
export const useShipById = (id, options = {}) => {
    const ship = useSelector(state => selectShipById(state, id))
    const shipsLoading = useSelector(state => state.ships.loading)
    const {deleteLoading, DeleteShip, deleteShip, deleteShipProps, isModalVisible: isDeleteModalVisible} = useDeleteShip(id, options?.delete || {})

    return {
        loading: shipsLoading,
        ship,
        deleteShip,
        deleteShipProps,
        deleteLoading,
        DeleteShip,
        isDeleteModalVisible,
    }
}
