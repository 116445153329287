import React, {useContext} from 'react';
import AppContext from "../../utils/AppContext";
import Alert from "@material-ui/lab/Alert";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const ProfielInfo = () => {
    const appContext = useContext(AppContext);
    const {t} = useTranslation();

    let percentageComplete = parseInt(appContext.userInfo.percentageComplete);
    let numberOfShips = parseInt(appContext.userInfo.numberOfShips);

    let userComplete = false;
    if (percentageComplete === 100 && numberOfShips > 0) {
        userComplete = true;
    }

    if (!userComplete) {
        return (
            <Alert id="userStatus" severity="info">
                {percentageComplete < 100 && numberOfShips <= 0 ? (
                    <Trans t={t} i18nKey="algemeen.melding-profiel-schip">Vul uw <Link to="/profiel">volledig profiel</Link> in en <Link to="/schepen">maak een schip</Link> aan om een havenbezoek te doen</Trans>
                ) : percentageComplete < 100 ? (
                    <Trans t={t} i18nKey="algemeen.melding-profiel">Vul uw <Link to="/profiel">volledig profiel</Link> in om een havenbezoek te doen</Trans>
                ) : (
                    <Trans t={t} i18nKey="algemeen.melding-schip"><Link to="/schepen">Maak een schip</Link> aan om een havenbezoek te doen</Trans>
                )}
            </Alert>
        );
    } else {
        return null;
    }
}
export default ProfielInfo
