import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useQuery} from "@apollo/client";
import {GET_PAGES_BY_LANG} from "../../Model/Queries";

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
}));

export default (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { loading, data } = useQuery(GET_PAGES_BY_LANG, {
        variables: {
            language: i18n.language,
            inMenu: true,
        }
    });

    return (
        <Drawer
            className={'drawer ' + classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={'drawer-div-toolbar ' + classes.toolbar} />
            <div className="navigatie">
                <List id="nav" disablePadding>
                    <ListItem button key={'havenbezoeken'} className="navigatie-item" disableGutters>
                        <Link to={'/'} className={(props.activeItem === '/' || props.activeItem === '/havenbezoek/nieuw' ? 'active' : '')}>
                            <Icon className="icon icon-anchor-circle"/>
                            <ListItemText primary={t('havenbezoeken.title')} />
                        </Link>
                    </ListItem>
                    <ListItem button key={'schepen'} className="navigatie-item" disableGutters>
                        <Link to={'/schepen'} className={(props.activeItem === '/schepen' || props.activeItem === '/schip/nieuw' ? 'active' : '')}>
                            <Icon className={'icon ' + (props.schepenComplete ? 'icon-ship-circle' : 'icon-ship-circle alert')}/>
                            <ListItemText primary={t('schepen.title')} />
                        </Link>
                    </ListItem>
                    <ListItem button key={'gebruikersprofiel'} className="navigatie-item" disableGutters>
                        <Link to={'/profiel'} className={(props.activeItem === '/profiel' ? 'active' : '')}>
                            <Icon className={'icon ' + (props.profielComplete ? 'icon-user-circle' : 'icon-user-circle alert')}/>
                            <ListItemText primary={t('profiel.title')} />
                        </Link>
                    </ListItem>
                    {(!loading && (data !== undefined) && (data.pages !== null) && (data.pages.length > 0)) &&
                    <>
                        {data.pages.map((item, key) => {
                            return (
                                <ListItem button key={key} className="navigatie-item" disableGutters>
                                    <Link to={'/' + item.Slug} className={(props.activeItem === ('/' + item.Slug) ? 'active' : '')}>
                                        <Icon className={'icon icon-anchor-circle'}/>
                                        <ListItemText primary={item.Title} />
                                    </Link>
                                </ListItem>
                            )
                        })}
                    </>
                    }
                </List>
                <div id="navbg" className="nav-bg" />
            </div>
        </Drawer>
    );
}
