import React, {useEffect, useState} from "react";
import Alert from "@material-ui/lab/Alert";

const Message = function (props) {
    const [isVisible, setIsVisible] = useState(true);
    const {message, remove} = props;

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(false);
            remove(message);
        }, message.duration);
    }, [message, remove])

    return (
        <>
            {isVisible &&
            <Alert severity={props.message.type} onClose={() => { props.remove(props.message) }}>
                {props.message.message}
            </Alert>
            }
        </>
    );
};

export default Message;