import React from "react";
import {configureStore} from "@reduxjs/toolkit";
import { Provider } from 'react-redux';
import rootReducer from './rootReducer';

export default (props) => {
    const store = configureStore({
        reducer: rootReducer,
    });

    return(
        <Provider store={store}>
            {props.children}
        </Provider>
    );
}

