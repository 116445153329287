import {useModal} from "./useModal";
import Modal from "../../../components/Feedback/Modal/DeletionModal";

export const useDeletionModal = ( isVisible, { closeCallback, openCallback, title = null, defaultStyles = true, onClickDelete, notificationText, deleteButtonText, errorText }) => {
   const {options, showModal, closeModal, isModalVisible} = useModal(isVisible, {
       closeCallback,
       openCallback,
       title,
       defaultStyles,
   })

    options.closeModal = closeModal
    options.onClickDelete = onClickDelete
    options.notificationText = notificationText
    options.deleteButtonText = deleteButtonText
    options.errorText = errorText

    return {
        Modal,
        options,
        showModal,
        closeModal,
        isModalVisible,
    }
}