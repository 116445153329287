import Modal from "../../../components/Feedback/Modal/Modal";
import useVisible from "../../Utils/useVisible";


export const useModal = ( isVisible, { closeCallback, openCallback, title = null, className, defaultStyles = true, loading = false }) => {
    const { ref, isVisible: isModalVisible, setIsVisible } = useVisible( isVisible )

    const showModal = (args) => {
        setIsVisible(true)
        if (openCallback) {
            openCallback(args)
        }
    }
    const closeModal = () => {
        setIsVisible(false)
    }

    const options = {
        closeCallback: () => {
            setIsVisible(false)
            if (closeCallback) {
                closeCallback()
            }
        },
        showModal: isModalVisible,
        reference: ref,
        title: title,
        className: className,
        defaultStyles: defaultStyles,
        loading: loading,
    }

    return {
        Modal,
        options,
        showModal,
        closeModal,
        isModalVisible,
    }
}