import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../Model/Queries";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setLoading, fetchUserId } from "../Reducers/userReducer";
import { useTranslation } from "react-i18next";
import auth from "../utils/auth";

const isLoggedIn = () => {
    let result = false;

    if (auth.getToken() !== null) {
        result = true;
    }

    if (localStorage && localStorage.getItem('jwtToken')) {
        const jwtDecode = require('jwt-decode');
        const jwt_decoded = jwtDecode(localStorage.getItem('jwtToken'));
        try {
            const now = Date.now().valueOf() / 1000;
            if (typeof jwt_decoded.exp !== 'undefined' && jwt_decoded.exp < now) {
                throw new Error(`Token expired: ${JSON.stringify(jwt_decoded)}`)
            }
        } catch (error) {
            console.error(error);
            //Clear info and logout
            auth.clearUserInfo();
            auth.clearToken();
            auth.clearAppStorage();
            localStorage.clear();
            result = false;
        }
    }
    return result;
}

export function useUser() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const userLoading = useSelector(state => state.user.loading);
    const userInitialized = useSelector(state => state.user.initialized);
    const { i18n } = useTranslation()

    const [loadUser, { error }] = useLazyQuery(GET_USER, {
        variables: {
            userId: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : null,
        },
        onCompleted: (data) => {
            if(data.schippers.data.length) {
                if (i18n.language !== data.schippers.data[0].attributes.taal) {
                    i18n.changeLanguage(data.schippers.data[0].attributes.taal)
                }
                dispatch(setUser(data.schippers.data[0]));
            } else {
                dispatch(fetchUserId());
            }
        },
        onError: (error) => {
            console.log( error );
            // Shipper not found, try fetching account only
            dispatch(fetchUserId());
        },
    });

    if (!userLoading && !userInitialized && isLoggedIn()) {
        dispatch(setLoading(true));
        loadUser();
    }

    const hasError = (error !== undefined);
    const loading = (userLoading || (!userInitialized));

    return { user, loading, isLoggedIn, hasError, error }
}
