import {useQuery} from "@apollo/client";
import {GET_PORT_VISITS} from "../Model/Queries";
import { useSelector, useDispatch } from "react-redux";
import {setPortVisits, selectAllPortVisits} from "../Reducers/portVisitsReducer";

export function usePortVisits() {
    const dispatch = useDispatch();
    const visits = useSelector(selectAllPortVisits);

    const { loading } = useQuery(GET_PORT_VISITS, {
        onCompleted: (data) => {
            if ((data === undefined) || (data.havenbezoeks.data === undefined)) return;
            dispatch(setPortVisits(data.havenbezoeks.data));
        }
    });

    return {visits, loading}
}
