import {GET_PAGE_BY_SLUG, GET_PAGE_BY_TAG} from "../Model/Queries";
import {useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";

export function usePage(params = {}) {
    const slug = params.hasOwnProperty('slug') ? params.slug : window.location.pathname.replace('/', '');
    const tag  = params.hasOwnProperty('tag') ? params.tag : null;
    const {i18n} = useTranslation()
    const query = (tag !== null) ? GET_PAGE_BY_TAG : GET_PAGE_BY_SLUG;


    const { loading, data } = useQuery(query, {
        variables: {
            slug: slug,
            tag: tag,
            language: i18n.language,
        }
    });

    const page = (!loading && (data !== undefined)) ? data.pages.data[0].attributes : {
        Title: null,
        Slug: null,
        Content: null,
        Intro: null,
    }
    const is404 = (!loading && (data !== undefined) && (data.pages !== null) && (data.pages.length < 1));

    return {page, loading, is404};
}
