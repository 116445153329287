import { useUser } from "../useUser";
import { useLazyQuery } from "@apollo/client";
import { GET_SHIP_TYPES, GET_USER_SCHIPS } from "../../Model/Queries";
import { useDispatch, useSelector } from "react-redux";
import { selectAllShips, setShips, setLoading, setShipTypes, setShipTypesLoading } from "../../Reducers/shipsReducer";

export function useShips() {
    const dispatch = useDispatch()
    const { user } = useUser()
    const userId = (user && (user.user !== undefined)) ? user.data.user.data.id : JSON.parse(localStorage.getItem('userInfo')).id
    const ships = useSelector(selectAllShips)
    const shipsLoading = useSelector(state => state.ships.loading)
    const shipsInitialized = useSelector(state => state.ships.initialized)

    const [loadShips, { loading, error }] = useLazyQuery(
        GET_USER_SCHIPS,
        {
            variables: {
                userID: userId,
                makeCall: userId !== null
            },
            onCompleted: (data) => {
                dispatch(setShips(data.usersPermissionsUser.data.attributes.schips.data));
                if ((data === undefined) || (data.usersPermissionsUser === undefined) || (data.usersPermissionsUser.data.attributes.schips.data === undefined)) return;
            },
        }
    );

    if (!shipsLoading && !shipsInitialized) {
        loadShips();
        dispatch(setLoading(true));
    }

    const shipTypesState = useSelector(state => state.ships.shipTypes);
    const shipTypes = shipTypesState.types;
    const [loadShipTypes, { loading: shipTypesLoading, error: shipTypesError }] = useLazyQuery(GET_SHIP_TYPES, {
        onCompleted: (data) => {
            if ((data === undefined) || (data.scheepstypes === undefined)) return;
            dispatch(setShipTypes(data.scheepstypes.data));
        },
    });

    if (!shipTypesState.loading && !shipTypesState.initialized) {
        loadShipTypes();
        dispatch(setShipTypesLoading(true));
    }

    const hasError = (error !== undefined);

    return { ships, loading, hasError, error, shipTypes, shipTypesLoading, shipTypesError }
}
