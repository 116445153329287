import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from '@material-ui/core/Grid';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useShipById, useShips } from "../../Hooks";
import useForm from "../../Hooks/Forms/useForm";
import useVat from "../../Hooks/Forms/useVat";
import { useNotifications } from "../../Hooks/useNotifications";
import helper from "../../assets/helper";
import AppContext from "../../utils/AppContext";
import request from "../../utils/request";
import Address from "./Address";

export default (props) => {
    const appContext = useContext(AppContext)
    const { shipTypes, ships } = useShips()
    const { push } = useHistory()
    const { loading: shipLoading, ship: shipData, deleteLoading, deleteShip, DeleteShip, deleteShipProps } = useShipById(props.match.params.id, {
        delete: {
            afterDelete: () => {
                push('/schepen')
            }
        }
    })
    const [loading, setLoading] = useState(true);
    const [isNew, setIsNew] = useState(true);
    const [error, setError] = useState('');
    const [helperText, setHelperText] = useState('');
    const [shipId, setShipId] = useState(props.match.params.id);
    const [ship, setShip] = useState({
        'name': '',
        'eni': '',
        'scheepstype': '',
        'lengte': '',
        'breedte': '',
        'diepte': '',
        'tonnagemax': '',
        'greenaward': 'geen',
        'greenaward_certificaat': '',
        'afwijkend_factuuradres': false,
    });
    const [address, setAddress] = useState({
        'factuur_bedrijfsnaam': '',
        'factuur_adres': '',
        'factuur_huisnr': '',
        'factuur_huisnrtoevoeging': '',
        'factuur_postcode': '',
        'factuur_plaats': '',
        'factuur_land': '103',
        'factuur_kvk': '',
        'factuur_btw': ''
    });

    const { validate: validateVat, isValid: vatNumberValid, vatError } = useVat();
    const { control: addressFormControl, validate: addressFormValidate, errors: addressFormErrors, validateBatch: validateAddresses } = useForm({
        factuur_adres: { alphaNumeric: true },
        factuur_postcode: { alphaNumeric: true },
        factuur_plaats: { alphaNumeric: true },
        factuur_land: { alphaNumeric: true },
        factuur_btw: { alphaNumeric: true },
    }, {}, {});
    const redirectUri = '/schepen'
    const { t } = useTranslation()
    const { appendNotification } = useNotifications()

    useEffect(() => {
        setLoading(false)
        if (!shipLoading && (shipData !== undefined)) {
            setIsNew(false);
            const data = { ...shipData };
            delete data.__typename;
            setShip(data.attributes);
            const addressData = { ...shipData.attributes.address };
            delete addressData.__typename;
            setAddress(addressData);
        }
    }, [shipData, shipLoading]);

    const handleChange = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'scheepstype') {
            setShipId(value);
        }

        if (name === 'tonnagemax') {
            if (value.length > 4) {
                setHelperText('Het ingevoerde getal is te groot');
            } else {
                setHelperText('');
            }
        }

        if (name === 'eni') {
            //filter decimal number
            value = value.replace(/([^\d]*)/g, "");

            //re set current input value
            event.target.value = value;

            setShip({
                ...ship,
                [name]: value,
                user: { id: helper.getUserInfo().userInfo.id },
            });
            return;
        }

        if ((name === 'lengte') || (name === 'breedte') || (name === 'diepte')) {

            //filter decimal number
            value = value.toString().replace(',', '.');
            value = value.toString().replace(',', '.');
            value = value.replace(/([^\d]*)(\d*(\.\d{0,2})?)(.*)/g, "$2");

            //re set current input value
            event.target.value = value;

            setShip({
                ...ship,
                [name]: value,
                user: { id: helper.getUserInfo().userInfo.id },
            });
            return;
        }

        setShip({
            ...ship,
            [name]: value,
            user: { id: helper.getUserInfo().userInfo.id },
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (ship.eni.length < 8) {
            window.scrollTo(0, 0);
            return;
        }
        if (ship.scheepstype === '') {
            window.scrollTo(0, 0);
            return;
        }

        if (ship.tonnagemax > 4000) {
            setHelperText('Het ingevoerde tonnage is te groot. Het tonnage mag niet groter zijn dan 4000.');
            window.scrollTo(0, 0);
            return;
        }

        if (!validateAddresses(address)) {
            setError('Formulier ongeldig');
            return;
        }

        setLoading(true);

        let postUrl;
        let method;

        if (isNew) {
            postUrl = process.env.REACT_APP_STRAPI_URL + '/schips/';
            method = 'POST';
        } else {
            postUrl = process.env.REACT_APP_STRAPI_URL + '/schips/' + props.match.params.id;
            method = 'PUT';
        }

        let shipPayload = ship.afwijkend_factuuradres ? {
            ...ship,
            address: address,
            user: { id: helper.getUserInfo().userInfo.id }
        } : ship;

        let data = { data: shipPayload };

        if (data.address?.factuur_kvk?.length < 1) {
            data.address.factuur_kvk = null
        }

        request(postUrl, { method: method, body: data })
            .then(postResponse => {
                appendNotification({
                    type: 'success',
                    message: t('schip.info.success'),
                });
                const numberShips = isNew ? ships.length + 1 : ships.length;
                helper.setUserNumberShips(numberShips);
                appContext.setUserInfo(helper.getUserInfo())
                setIsNew(false)
                helper.checkUserComplete();
                window.location.href = redirectUri;
            })
            .catch(postError => {
                setLoading(false);
                setError(postError);
            })
    }

    if (shipLoading) return (<CircularProgress />);
    if (error) {
        return (
            <div className="schip">
                <h1>{t('schip.documentTitleNew')}</h1>
                <div className="Error">{error}</div>
            </div>
        );
    }

    let scheepsType;
    if (ship.hasOwnProperty('scheepstype') && ship.scheepstype.data !== undefined && ship.scheepstype.data.hasOwnProperty('id')) {
        scheepsType = ship.scheepstype.data.id;
    } else if (shipId) {
        scheepsType = shipId;
    } else if (ship.hasOwnProperty('scheepstype')) {
        scheepsType = shipTypes;
    }

    const shipMeasurementsInputProps = {
        step: 0.01,
    };

    return (
        <div className="schip">
            <div className="schip-container">
                <section className="content">
                    <h1>{isNew ? t('schip.documentTitleNew') : ship.name}</h1>
                    <p>{t('schip.intro')}</p>
                </section>
                <section className="form">
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <FormControl fullWidth required variant="outlined">
                            <TextField
                                id="name"
                                label={t('schip.scheepsnaam')}
                                type="text"
                                name="name"
                                onChange={handleChange}
                                value={ship.name}
                                margin="normal"
                                variant="outlined"
                                required
                            />
                        </FormControl>

                        <FormControl fullWidth required variant="outlined">
                            <TextField
                                id="eni"
                                label={t('schip.eni')}
                                type="text"
                                name="eni"
                                onChange={handleChange}
                                value={ship.eni !== '' ? ship.eni : ''}
                                margin="normal"
                                variant="outlined"
                                inputProps={{ maxLength: 8 }}
                                error={(ship.eni.length < 8)}
                                helperText={(ship.eni.length < 8) ? t('schip.eni_error') : ""}
                                required
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                select
                                id="scheepstype"
                                name="scheepstype"
                                className={'scheepstype'}
                                label={t('schip.scheepstype')}
                                value={scheepsType ? scheepsType : ''}
                                onChange={handleChange}
                                margin="normal"
                                variant="outlined"
                                error={(ship.scheepstype === '')}
                                required
                                fullWidth
                            >
                                {shipTypes.map(type => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {t('scheepstype.' + type.attributes.identifier)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth required variant="outlined">
                                    <TextField
                                        id="lengte"
                                        label={t('schip.lengte')}
                                        type="text"
                                        name="lengte"
                                        onChange={handleChange}
                                        value={ship.lengte}
                                        inputProps={shipMeasurementsInputProps}
                                        margin="normal"
                                        variant="outlined"
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth required variant="outlined">
                                    <TextField
                                        id="breedte"
                                        label={t('schip.breedte')}
                                        type="text"
                                        name="breedte"
                                        onChange={handleChange}
                                        value={ship.breedte}
                                        inputProps={shipMeasurementsInputProps}
                                        margin="normal"
                                        variant="outlined"
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth required variant="outlined">
                                    <TextField
                                        id="diepte"
                                        label={t('schip.diepte')}
                                        type="text"
                                        name="diepte"
                                        onChange={handleChange}
                                        value={ship.diepte}
                                        inputProps={shipMeasurementsInputProps}
                                        margin="normal"
                                        variant="outlined"
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <FormControl fullWidth required variant="outlined">
                            <TextField
                                id="tonnagemax"
                                label={t('schip.tonnage-max')}
                                type="number"
                                name="tonnagemax"
                                onChange={handleChange}
                                value={ship.tonnagemax}
                                margin="normal"
                                variant="outlined"
                                error={ship.tonnagemax.length > 4}
                                helperText={helperText}
                                required
                                inputProps={{
                                    maxLength: 4,
                                }}
                            />
                        </FormControl>

                        <Grid container>
                            <Grid item xs={11}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        id="greenaward"
                                        name="greenaward"
                                        className={'greenaward'}
                                        label={t('schip.greenaward')}
                                        value={ship.greenaward}
                                        onChange={handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        required
                                        fullWidth
                                    >
                                        <MenuItem value="geen">{t('schip.greenaward-geen')}</MenuItem>
                                        <MenuItem value="brons">{t('schip.greenaward-brons')}</MenuItem>
                                        <MenuItem value="zilver">{t('schip.greenaward-zilver')}</MenuItem>
                                        <MenuItem value="goud">{t('schip.greenaward-goud')}</MenuItem>
                                        <MenuItem value="platinum">{t('schip.greenaward-platinum')}</MenuItem>
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title={t('schip.info.greenaward')}>
                                    <Icon className="icon-info" />
                                </Tooltip>
                            </Grid>
                        </Grid>

                        {ship.greenaward !== 'geen' &&
                            <FormControl fullWidth required variant="outlined">
                                <TextField
                                    id="greenaward_certificaat"
                                    label={t('schip.greenaward-certificaat')}
                                    type="textr"
                                    name="greenaward_certificaat"
                                    onChange={handleChange}
                                    defaultValue={ship.greenaward_certificaat}
                                    margin="normal"
                                    variant="outlined"
                                    required
                                />
                            </FormControl>
                        }


                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox name="afwijkend_factuuradres" checked={ship.afwijkend_factuuradres} onChange={handleChange} value="1" color="primary" />
                                }
                                label={t('profiel.afwijkend_factuuradres')}
                            />
                        </Grid>

                        {ship.afwijkend_factuuradres &&
                            <Address
                                values={address}
                                onChange={(value, name) => {
                                    setAddress({
                                        ...address,
                                        [name]: value,
                                    })
                                }}
                                validateVatNumber={{ validateVat, vatNumberValid, vatError }}
                                formControl={{ addressFormControl, addressFormValidate, addressFormErrors }}
                            />
                        }

                        <Grid container justifyContent="flex-end">
                            {!isNew &&
                                <Tooltip title={t('algemeen.delete')}>
                                    <Link
                                        style={{ marginTop: 20, marginRight: 10 }}
                                        onClick={() => deleteShip(ship)}
                                        to={'#'}
                                    >
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            }
                            <Button
                                label="Submit"
                                primary="true"
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading || deleteLoading || (ship.afwijkend_factuuradres && (!validateAddresses(address) || (address.factuur_land !== '103' && !vatNumberValid)))}
                            >{isNew ? t('schip.btn-add') : t('schip.btn-save')}</Button>
                        </Grid>
                    </form>
                </section>
            </div>
            <DeleteShip {...deleteShipProps} />
        </div>
    );
}
