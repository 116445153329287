import {useMutation} from "@apollo/client";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {DELETE_SHIP} from "../../Model/Mutations";
import {GET_USER_SCHIPS} from "../../Model/Queries";
import {useDeletionModal} from "../Ui/Feedback/useDeletionModal";
import {removeShip, selectShipById} from "../../Reducers/shipsReducer";
import {useNotifications} from "../useNotifications";

/**
 * @param {Number} id
 * @param {{afterDelete?: (function(): void)}} options
 * @returns {{deleteShipProps: {reference: React.MutableRefObject<null>, closeCallback: function(): void, className: *, title: null, loading: boolean, showModal: *, defaultStyles: boolean}, DeleteShip: ((function(*): *)|*), deleteShip: deleteShip, isModalVisible: (*), deleteLoading: boolean}}
 */
export const useDeleteShip = (id, options = {}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {appendNotification} = useNotifications()
    const [doDeleteShip, {loading: deleteLoading}] = useMutation(DELETE_SHIP, {
        refetchQueries: [GET_USER_SCHIPS],
    })
    const shipToDelete = useSelector(state => selectShipById(state, id))
    const { Modal: DeleteShip, options: deleteShipProps, showModal, isModalVisible, closeModal } = useDeletionModal( false, {
        onClickDelete: async () => {
            if (deleteLoading || !shipToDelete) {
                return
            }
            await doDeleteShip({
                variables: {
                    id: shipToDelete.id
                }
            })

            closeModal()
            dispatch(removeShip({
                id: shipToDelete.id,
            }))
            appendNotification({
                type: 'success',
                message: t('schip.info.deleted'),
            })
            if (options?.afterDelete !== undefined) {
                options.afterDelete()
            }
        },
        title: t('schepen.delete.title'),
        deleteButtonText: t('algemeen.delete'),
        notificationText: t('schepen.delete.notification', {ship: shipToDelete?.name || ''}),
        errorText: null,
        loading: deleteLoading,
    } )
    const deleteShip = (ship, callback = null) => {
        showModal({
            ship,
            callback,
        })
    }

    return {
        deleteShip,
        deleteLoading,
        DeleteShip,
        deleteShipProps,
        isModalVisible,
    }
}