import { useMutation, useQuery } from "@apollo/client";
import { useUser } from "./useUser";
import { GET_MESSAGES } from "../Model/Queries";
import { UPDATE_MESSAGE } from "../Model/Mutations";

export const useMessages = () => {
    const { user } = useUser();

    const { data } = useQuery(
        GET_MESSAGES
    );

    let messages = []
    if (data && data.berichten !== undefined) {
        messages = data.berichten
    }

    const [updateMessage, { error: updateMessageError }] = useMutation(UPDATE_MESSAGE, {
        refetchQueries: () => [
            { query: GET_MESSAGES },
        ]
    })

    if (updateMessageError) {
        console.log('updateMessageError', updateMessageError);
    }

    const handleMessageClose = (id) => {
        let messageData = {
            id: id,
            gelezen: true,
            user: user
        }

        updateMessage({ variables: messageData })
    }

    let showableMessages = []
    if(messages.data?.length > 0){
        messages.data.forEach((message) => {
            if (message.attributes.Gelezen === false) {
                showableMessages.push(message)
            }
        })
    }

    return { messages, handleMessageClose, showableMessages }
}