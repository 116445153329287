import React from 'react';
import Header from '../Header';
import Navigatie from '../Navigatie';
import Main from '../Main/Main';
import {withRouter} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

class Template extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showTemplate: true,
            activeRoute: '',
            drawerWidth: 240,
        };

        this.props.history.listen((location, action) => {
            this.setState({activeRoute: location.pathname});
            this.checkPathForTemplate();
        });
    }

    checkPathForTemplate() {
        let requestUrl = window.location.href;
        if (requestUrl.match(/auth/g) || this.state.activeRoute.match('/auth/g')) {
            this.setState({showTemplate: false});
        }
    }

    async componentDidMount() {
        this.checkPathForTemplate();
    }


    render() {

        if (!this.state.showTemplate) {
            return (
                <div id="template">
                    {this.props.children}
                </div>
            );
        } else {
            return (
                <div className="main">
                    <CssBaseline />
                    <Header />
                    <Navigatie location={this.props.location}/>
                    <Main children={this.props.children}/>
                </div>
            );
        }
    }
}

export default withRouter(Template);