import React from 'react';
import helper from "../../assets/helper/";
import Message from './message.js'

class Notifications extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            activeItem: '',
            visible: true,
            timerRunning: false,
        };

        this.hideNotification = this.hideNotification.bind(this);
    }

    checkMessages() {
        let currentMessages = this.state.messages;
        let localMessages = localStorage.getItem('messages');
        if(localMessages !== null) {
            if(currentMessages.length !== localMessages.length) {
                let messages = JSON.parse(localMessages);
                this.setState({
                    messages: messages,
                    visible: true,
                });
                if (this.state.messages.length > 0) {
                    if (!this.state.timerRunning) {
                        this.setState({timerRunning: true});
                        this.hideNotificationInterval = setInterval(
                            () => this.hideNotification(),
                            10000
                        );
                    }
                }
            }
        }
    }

    async componentDidMount() {
        this.notificationInterval = setInterval(
            () => this.checkMessages(), 2000
        );

        if (this.state.messages.length > 0) {
            this.hideNotificationInterval = setInterval(
                () => this.hideNotification(),
                10000
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.notificationInterval);
    }

    hideNotification() {
        clearInterval(this.hideNotificationInterval);
        this.setState({timerRunning: false});
        helper.removeNotification();
    }

    render() {
        if(this.state.messages.length > 0) {
            return (
                <div className="notifications">
                    {this.state.messages.map((message, index) => {
                        return (
                            <Message message={message} key={index} hideNotification={this.hideNotification} visible={this.state.visible}/>
                        )
                    })}
                </div>
            )
        } else {
            return null;
        }
    }
}

export default Notifications;