import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {useShips} from "../../../Hooks";
import {usePortVisit} from "../../../Hooks/usePortVisit";

export default () => {
    const {portVisit: havenBezoek, setPortVisit: setHavenBezoek, errors} = usePortVisit();
    const {ships, loading} = useShips();
    const { t } = useTranslation();

    const getSchipById = (id) => {
        let match = ships.filter((item) => {
            return (item.id === id);
        })

        return match.length ? match[0] : {};
    }

    return (
        <>
            <Grid item xs={9}>
                <TextField
                    select
                    id="outlined-select-ship"
                    className={'ship-select'}
                    label={t('havenbezoek.schip')}
                    value={havenBezoek.schip.id !== undefined ? havenBezoek.schip.id : ''}
                    name="schip"
                    onChange={(event) => {
                        setHavenBezoek({
                            ...havenBezoek,
                            schip: getSchipById(event.target.value),
                        })
                    }}
                    variant="outlined"
                    fullWidth
                    required
                    disabled={loading}
                    error={(errors.hasOwnProperty('schip') && errors.schip)}
                >
                    {ships.map((schip, index) => (
                        <MenuItem key={schip.id} value={schip.id}>
                            {schip.attributes.name}, {schip.attributes.eni}, {schip.attributes.tonnagemax}t
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={3}>
                <Link className={"btn"} to="/schip/nieuw">
                    <Button label="Submit"
                            primary="true"
                            variant="contained"
                            className="new-schip"
                            color="primary">{t('havenbezoek.nieuw-schip')}</Button>
                </Link>
            </Grid>
        </>
    )
}
