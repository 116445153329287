import React from "react";
import {useUser} from "../../Hooks/useUser";
import Store from "../../Reducers/Store";
import Client from "../../Model/Client";

const Wrapper = (props) => {
    useUser()

    return (
        <Client>
            <Store>
                {props.children}
            </Store>
        </Client>
    )
}
export default Wrapper