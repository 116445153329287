import {useState} from "react";
import {useTranslation} from "react-i18next";


const useVat = () => {
    const checkVatUrl = process.env.REACT_APP_SYNC+'/checkvat';
    const [vatNumber, setVatNumber] = useState('');
    const [countryCode, setCountryCode] = useState('103');
    const [vatError, setVatError] = useState('');
    const [isValid, setIsValid] = useState(true);
    const {t} = useTranslation();


    const validate = async (validateVatNumber, validateCountryCode, callback) => {
        fetch(checkVatUrl + '?countryCode=' + validateCountryCode + '&vatNumber=' + validateVatNumber)
            .then(response => response.json())
            .then(response => {
                setVatNumber(vatNumber);
                setCountryCode(countryCode);
                setVatError(response ? '' : t('algemeen.error.vat-invalid'));
                setIsValid(response);
                if (typeof callback === 'function') {
                    callback(response, vatNumber, countryCode);
                }
            })
    }

    return {vatNumber, countryCode, vatError, isValid, validate}

}
export default useVat;