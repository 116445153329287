import React from 'react'
import {withRouter} from 'react-router-dom';
import Items from './items.js';
import AppContext from '../../utils/AppContext';

class Navigatie extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            activeItem: ''
        };

        this.props.history.listen((location, action) => {
            this.setState({activeItem: location.pathname});
        });
    }

    async componentDidMount() {
        this.setState({activeItem: this.props.location.pathname});
    }

    render() {
        return (
            <Items activeItem={this.state.activeItem} profielComplete={parseInt(this.context.userInfo.percentageComplete) === 100 ? true : false} schepenComplete={this.context.userInfo.numberOfShips > 0 ? true : false}/>
        );
    }
}

export default withRouter(Navigatie);