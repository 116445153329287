import React from 'react'
import { useMessages } from '../../Hooks/useMessages'
import Alert from "@material-ui/lab/Alert";

export const Messages = () => {
    const { handleMessageClose, showableMessages } = useMessages();
    if (showableMessages.length > 0) {
        return (
            <>
                {showableMessages.map((bericht) => {
                    return (
                        <Alert key={bericht.id} severity="info" onClose={() => { handleMessageClose(bericht.id) }}>
                            {bericht.attributes.Berichtinhoud}
                        </Alert>
                    )
                })}
            </>
        )
    }
    return <></>
}
