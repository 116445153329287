import {GET_HELPPAGE} from "../Model/Queries";
import {useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";

export function useHelpPage(params = {}) {
    const {i18n} = useTranslation()
    const query = GET_HELPPAGE;


    const { loading, data } = useQuery(query, {
        variables: {
            tag: "help",
            language: i18n.language
        },
    });

    const helpPage = (!loading && (data !== undefined)) ? data.pages.data[0].attributes : {
        Title: null,
        Content: null,
    }
    const is404 = (!loading && (data !== undefined) && (data.pages !== null) && (data.pages.length < 1));

    return {helpPage, loading, is404};
}
