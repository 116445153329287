import React from 'react';
import {withTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import request from "../../utils/request";

class UpdateInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showMessage: true,
            lastUpdateRaw: '',
            lastUpdateDate: '',
            lastUpdateTime: ''
        };

        this.handleClickClose = this.handleClickClose.bind(this);
    }


    async componentDidMount() {
        let requestURL = process.env.REACT_APP_STRAPI_URL+'/logs?pagination[start]=0&pagination[limit]=1&sort=createdAt:desc';

        if (localStorage && localStorage.getItem('showLastUpdate')) {
            let showMessage = localStorage.getItem('showLastUpdate');
            this.setState({showMessage: (showMessage === 'true')});
        }

        request(requestURL, {method: 'GET'})
            .then(response => {
                let dateOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
                let lastUpdate = new Date(response.data[0].attributes.createdAt);
                let lastUpdateDate = lastUpdate.toLocaleDateString('nl-NL', dateOptions);
                let lastUpdateTime = lastUpdate.getHours()+':'+(lastUpdate.getMinutes()<10?'0':'') + lastUpdate.getMinutes();
                this.setState({loading: false, lastUpdateRaw: response.data[0].attributes.createdAt, lastUpdateDate: lastUpdateDate, lastUpdateTime: lastUpdateTime});

                let lastUpdatedStorage = localStorage.getItem('lastUpdateRaw');
                if(lastUpdatedStorage !== null) {
                    let lastUpdatedStorageDate = new Date(lastUpdatedStorage);
                    if(lastUpdate > lastUpdatedStorageDate) {
                        localStorage.setItem('showLastUpdate', true);
                        this.setState({showMessage: true});
                    }
                }

            })
            .catch(err => {
                this.setState({showMessage: false});
            });
    }

    handleClickClose() {
        this.setState({showMessage: false});
        localStorage.setItem('showLastUpdate', false);
        localStorage.setItem('lastUpdateRaw', this.state.lastUpdateRaw);
    }

    render() {
        if (!this.state.loading && this.state.showMessage) {
            return (
                <Alert id="updateStatus" severity="info" onClose={() => { this.handleClickClose() }}>
                    {this.props.t('algemeen.bijgewerkt', {date: this.state.lastUpdateDate, time: this.state.lastUpdateTime})}
                </Alert>
            );
        }else{
            return null;
        }
    }
}

export default withTranslation()(UpdateInfo);
