import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import { makeStyles } from '@material-ui/core/styles';
import ProfielInfo from '../ProfielInfo/ProfielInfo';
import Notifications from '../Notifications';
import Notifications2 from "../Notifications2/Notifications2";
import { Messages } from '../../components/Messages/Messages';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
}));

export default function Main(props) {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid item>
                <div id="content">
                    <Container>
                        <ProfielInfo />
                        <Notifications />
                        <Notifications2 />
                        <Messages />
                        {props.children}
                    </Container>
                </div>
            </Grid>
        </main>
    );
}