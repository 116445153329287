import React from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../Hooks/useUser";
import { usePortVisit } from "../../../Hooks/usePortVisit";
import { useCountries } from "../../../Hooks/useCountries";


export default () => {
    const { portVisit: havenBezoek, setPortVisit: setHavenBezoek, errors } = usePortVisit();
    const { countries } = useCountries();
    const { t } = useTranslation();
    const { user, loading } = useUser();

    if (loading) {
        return <></>;
    }

    const getBillingString = () => {
        if ((loading || (user === undefined)) && !havenBezoek.afwijkend_factuuradres) {
            return '';
        }

        let defaultBilling = [user.data.factuur_bedrijfsnaam, user.data.factuur_adres + ' ' + user.data.factuur_huisnr + (user.data.factuur_huisnrtoevoeging ?? ''), user.data.factuur_postcode, user.data.factuur_plaats].filter(Boolean).join(', ');
        if ((havenBezoek.schip.address !== undefined) && (havenBezoek.schip.address !== null)) {
            const havenBezoekAddress = havenBezoek.schip.address;

            defaultBilling = [havenBezoekAddress.factuur_bedrijfsnaam, havenBezoekAddress.factuur_adres + (havenBezoekAddress.factuur_huisnr ? ` ${havenBezoekAddress.factuur_huisnr}` : '') + (havenBezoekAddress.factuur_huisnrtoevoeging ?? ''), havenBezoekAddress.factuur_postcode, havenBezoekAddress.factuur_plaats].filter(Boolean).join(', ');
        }

        return (havenBezoek.afwijkend_factuuradres) ?
            [havenBezoek.factuuradres_bedrijfsnaam, havenBezoek.factuuradres_adres + ' ' + havenBezoek.factuuradres_huisnr + (havenBezoek.factuuradres_huisnrtoevoeging ?? ''), havenBezoek.factuuradres_postcode, havenBezoek.factuuradres_plaats].filter(Boolean).join(', ') :
            defaultBilling;
    }

    return (
        <>
            <Grid item xs={12}>
                <FormControl fullWidth required variant="outlined">
                    <TextField
                        id="default_factuur"
                        label={t('havenbezoek.factuuradres')}
                        type="text"
                        name="default_factuur"
                        value={getBillingString()}
                        margin="normal"
                        variant="outlined"
                        error={!havenBezoek.afwijkend_factuuradres && errors.hasOwnProperty('billing') && errors.billing}
                        helperText={!havenBezoek.afwijkend_factuuradres && errors.hasOwnProperty('billing') && errors.billing ? t('havenbezoek.billingerror') : ''}
                        disabled
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox name="afwijkend_factuuradres" checked={havenBezoek.afwijkend_factuuradres} onChange={() => setHavenBezoek({
                            ...havenBezoek,
                            afwijkend_factuuradres: !havenBezoek.afwijkend_factuuradres
                        })} value="1" color="primary" />
                    }
                    label={t('havenbezoek.afwijkend_factuuradres')}
                />
            </Grid>
            {havenBezoek.afwijkend_factuuradres &&
                <Grid item xs={12}>
                    <h2>{t('havenbezoek.factuuradres')}</h2>
                    <p>{t('havenbezoek.info.factuuradres')}</p>
                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="bedrijfsnaam"
                            label={t('havenbezoek.bedrijfsnaam')}
                            type="text"
                            name="factuuradres_bedrijfsnaam"
                            defaultValue={havenBezoek.factuuradres_bedrijfsnaam}
                            margin="normal"
                            variant="outlined"
                            onChange={e => {
                                setHavenBezoek({
                                    ...havenBezoek,
                                    factuuradres_bedrijfsnaam: e.target.value,
                                })
                            }}
                            required
                            error={(errors.hasOwnProperty('factuuradres_bedrijfsnaam') && errors.factuuradres_bedrijfsnaam)}
                        />
                    </FormControl>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth required variant="outlined">
                                <TextField
                                    id="factuuradres_adres"
                                    label={t('havenbezoek.adres')}
                                    type="text"
                                    name="factuuradres_adres"
                                    defaultValue={havenBezoek.factuuradres_adres}
                                    variant="outlined"
                                    margin="normal"
                                    onChange={e => {
                                        setHavenBezoek({
                                            ...havenBezoek,
                                            factuuradres_adres: e.target.value,
                                        })
                                    }}
                                    required
                                    error={(errors.hasOwnProperty('factuuradres_adres') && errors.factuuradres_adres)}
                                />
                            </FormControl>
                        </Grid>

                        {parseInt(havenBezoek.factuuradres_land) === 103 &&
                            <Grid item xs={4} >
                                <FormControl fullWidth required variant="outlined">
                                    <TextField
                                        id="factuuradres_huisnr"
                                        label={t('havenbezoek.huisnr')}
                                        type="number"
                                        name="factuuradres_huisnr"
                                        defaultValue={havenBezoek.factuuradres_huisnr}
                                        variant="outlined"
                                        margin="normal"
                                        onChange={e => {
                                            setHavenBezoek({
                                                ...havenBezoek,
                                                factuuradres_huisnr: e.target.value,
                                            })
                                        }}
                                        error={(errors.hasOwnProperty('factuuradres_huisnr') && errors.factuuradres_huisnr)}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        {parseInt(havenBezoek.factuuradres_land) === 103 &&
                            <Grid item xs={4}>
                                <FormControl fullWidth required variant="outlined">
                                    <TextField
                                        id="factuuradres_huisnrtoevoeging"
                                        label={t('havenbezoek.huisnrtoevoeging')}
                                        type="text"
                                        name="factuuradres_huisnrtoevoeging"
                                        defaultValue={havenBezoek.factuuradres_huisnrtoevoeging}
                                        variant="outlined"
                                        margin="normal"
                                        onChange={e => {
                                            setHavenBezoek({
                                                ...havenBezoek,
                                                factuuradres_huisnrtoevoeging: e.target.value,
                                            })
                                        }}
                                        error={(errors.hasOwnProperty('factuuradres_huisnrtoevoeging') && errors.factuuradres_huisnrtoevoeging)}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4}>
                            <FormControl fullWidth required variant="outlined">
                                <TextField
                                    id="factuuradres_postcode"
                                    label={t('havenbezoek.postcode')}
                                    type="text"
                                    name="factuuradres_postcode"
                                    defaultValue={havenBezoek.factuuradres_postcode}
                                    variant="outlined"
                                    margin="normal"
                                    onChange={e => {
                                        setHavenBezoek({
                                            ...havenBezoek,
                                            factuuradres_postcode: e.target.value,
                                        })
                                    }}
                                    required
                                    error={(errors.hasOwnProperty('factuuradres_postcode') && errors.factuuradres_postcode)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth required variant="outlined">
                                <TextField
                                    id="factuuradres_plaats"
                                    label={t('havenbezoek.plaats')}
                                    type="text"
                                    name="factuuradres_plaats"
                                    defaultValue={havenBezoek.factuuradres_plaats}
                                    variant="outlined"
                                    margin="normal"
                                    onChange={e => {
                                        setHavenBezoek({
                                            ...havenBezoek,
                                            factuuradres_plaats: e.target.value,
                                        })
                                    }}
                                    required
                                    error={(errors.hasOwnProperty('factuuradres_plaats') && errors.factuuradres_plaats)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    id="factuuradres_land"
                                    className={'factuuradres_land'}
                                    label={t('havenbezoek.land')}
                                    value={havenBezoek.factuuradres_land !== 'undefined' ? havenBezoek.factuuradres_land : ''}
                                    name="factuuradres_land"
                                    onChange={e => setHavenBezoek({
                                        ...havenBezoek,
                                        factuuradres_land: e.target.value,
                                    })}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    error={(errors.hasOwnProperty('factuuradres_land') && errors.factuuradres_land)}
                                >
                                    {countries.map((land, index) => (
                                        <MenuItem key={land.attributes.landcode} value={land.attributes.landcode}>
                                            {land.attributes.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                        {parseInt(havenBezoek.factuuradres_land) === 103
                            ? <Grid item xs={12}>
                                <FormControl fullWidth required variant="outlined">
                                    <TextField
                                        id="factuuradres_kvk"
                                        label={t('havenbezoek.kvk')}
                                        type="number"
                                        name="factuuradres_kvk"
                                        onChange={e => setHavenBezoek({
                                            ...havenBezoek,
                                            factuuradres_kvk: e.target.value,
                                        })}
                                        defaultValue={havenBezoek.factuuradres_kvk}
                                        margin="normal"
                                        variant="outlined"
                                        required={parseInt(havenBezoek.factuuradres_land) === 103}
                                        visible={parseInt(havenBezoek.factuuradres_land) === 103}
                                        error={(errors.hasOwnProperty('factuuradres_kvk') && errors.factuuradres_kvk)}
                                    />
                                </FormControl>
                            </Grid>
                            : null}
                    </Grid>


                    <FormControl fullWidth required variant="outlined">
                        <TextField
                            id="factuuradres_btw"
                            label={t('havenbezoek.btw')}
                            type="text"
                            name="factuuradres_btw"
                            onChange={e => setHavenBezoek({
                                ...havenBezoek,
                                factuuradres_btw: e.target.value,
                            })}
                            defaultValue={havenBezoek.factuuradres_btw}
                            margin="normal"
                            variant="outlined"
                            error={(errors.hasOwnProperty('factuuradres_btw') && errors.factuuradres_btw)}
                            required={parseInt(havenBezoek.factuuradres_land) !== 103}
                        />
                    </FormControl>
                </Grid>
            }
        </>
    );
}
