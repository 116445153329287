import React from "react";
import Alert from "@material-ui/lab/Alert";

const Message = function (props) {

    return (
        <div>
            {props.visible &&
            <Alert id="messagePortal" severity={props.message.type} onClose={() => { props.hideNotification() }}>
                {props.message.message}
            </Alert>
            }
        </div>

    );
};

export default Message;