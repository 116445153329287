import React from "react";
import {usePortVisit} from "../../../Hooks/usePortVisit";
import {useTranslation} from "react-i18next";
import {Button, ButtonGroup, Grid} from "@material-ui/core";
import ShippingContentsLoading from "./ShippingContentsLoading";
import ShippingContentsUnLoading from "./ShippingContentsUnLoading";

export default () => {
    const {portVisit: havenBezoek, setPortVisit: setHavenBezoek} = usePortVisit();
    const visitTypeLoading = havenBezoek.visit_type_loading;
    const loading_unit = havenBezoek.loading_unit;
    
    const visitTypeUnLoading = havenBezoek.visit_type_unloading;
    const unloading_unit = havenBezoek.unloading_unit;

    const {t} = useTranslation();

    return (
        <>
            <Grid item container>
                <Grid item xs={12} md={2}>
                    <div className="opt-group">
                        <h3>{t('havenbezoek.visit_type_loading_label')}</h3>
                        <ButtonGroup color="primary">
                            <Button
                                disableElevation
                                variant={(!visitTypeLoading ? 'contained' : 'outlined')}
                                onClick={() => setHavenBezoek({
                                    ...havenBezoek,
                                    visit_type_loading: false,
                                })}>
                                {t('algemeen.nee')}
                            </Button>
                            <Button
                                disableElevation
                                variant={(visitTypeLoading ? 'contained' : 'outlined')}
                                onClick={() => setHavenBezoek({
                                    ...havenBezoek,
                                    visit_type_loading: true,
                                })}>
                                {t('algemeen.ja')}
                            </Button>
                        </ButtonGroup>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    {(visitTypeLoading) &&
                    <div className="opt-group">
                        <h3>{t('havenbezoek.unit_label')}</h3>
                        <ButtonGroup color="primary">
                            <Button
                                disableElevation
                                variant={(loading_unit === 'containers') ? 'contained' : 'outlined'}
                                onClick={() => setHavenBezoek({
                                    ...havenBezoek,
                                    loading_unit: 'containers',
                                })}>
                                {t('havenbezoek.unit_containers')}
                            </Button>
                            <Button
                                disableElevation
                                variant={(loading_unit === 'goods') ? 'contained' : 'outlined'}
                                onClick={() => setHavenBezoek({
                                    ...havenBezoek,
                                    loading_unit: 'goods',
                                })}>
                                {t('havenbezoek.unit_goods')}
                            </Button>
                        </ButtonGroup>
                    </div>
                    }
                    <ShippingContentsLoading />
                </Grid>
            </Grid>
            
            <Grid item container>
                <Grid item xs={12} md={2}>
                    <div className="opt-group">
                        <h3>{t('havenbezoek.visit_type_unloading_label')}</h3>
                        <ButtonGroup color="primary">
                            <Button
                                disableElevation
                                variant={(!visitTypeUnLoading ? 'contained' : 'outlined')}
                                onClick={() => setHavenBezoek({
                                    ...havenBezoek,
                                    visit_type_unloading: false,
                                })}>
                                {t('algemeen.nee')}
                            </Button>
                            <Button
                                disableElevation
                                variant={(visitTypeUnLoading ? 'contained' : 'outlined')}
                                onClick={() => setHavenBezoek({
                                    ...havenBezoek,
                                    visit_type_unloading: true,
                                })}>
                                {t('algemeen.ja')}
                            </Button>
                        </ButtonGroup>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    {(visitTypeUnLoading) &&
                    <div className="opt-group">
                        <h3>{t('havenbezoek.unit_label')}</h3>
                        <ButtonGroup color="primary">
                            <Button
                                disableElevation
                                variant={(unloading_unit === 'containers') ? 'contained' : 'outlined'}
                                onClick={() => setHavenBezoek({
                                    ...havenBezoek,
                                    unloading_unit: 'containers',
                                })}>
                                {t('havenbezoek.unit_containers')}
                            </Button>
                            <Button
                                disableElevation
                                variant={(unloading_unit === 'goods') ? 'contained' : 'outlined'}
                                onClick={() => setHavenBezoek({
                                    ...havenBezoek,
                                    unloading_unit: 'goods',
                                })}>
                                {t('havenbezoek.unit_goods')}
                            </Button>
                        </ButtonGroup>
                    </div>
                    }
                    <ShippingContentsUnLoading />
                </Grid>
            </Grid>
        </>
    );
}