import { useQuery } from "@apollo/client";
import { GET_COUNTRIES } from "../Model/Queries";

export function useCountries() {
    const { data, loading, error } = useQuery(
        GET_COUNTRIES,
        {
            variables: {
                start: 0,
                limit: 500,
            }
        }
    );

    const countries = ((data !== undefined) && (data.landens !== undefined)) ?
        data.landens.data.filter(country => country.attributes.landcode !== null) : [];

    return { countries, loading, error };
}
