import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";

export default (props) => {
    const {t} = useTranslation();
    const row = props.row;

    const volume = getVolume();
    const unit = ((row.attributes.unit === 'containers') || (row.attributes.unit === 'goods')) ? t('havenbezoeken.unit_' + row.attributes.unit) : '';

    const laden_volume = getVolumeLaden();
    const lossen_volume = getVolumeLossen();

    function getVolume() {
        if (row.attributes.unit === undefined) return '';
        if (row.attributes.unit === 'containers') return (row.attributes.teu !== undefined) ? row.attributes.teu : '';
        if (row.attributes.unit === 'goods') return ((row.attributes.shipping_contents !== undefined) && (row.attributes.shipping_contents.ton_goods !== undefined)) ? row.attributes.shipping_contents.ton_goods : '';
        return '';
    }

    function getVolumeLaden() {
        if (row.attributes.laden_unit === undefined) return '';
        if (row.attributes.laden_unit === 'containers') return (row.attributes.laden_teu !== undefined) ? row.attributes.laden_teu : '';
        if (row.attributes.laden_unit === 'goods') return ((row.attributes.laden_shipping_contents !== undefined) && (row.attributes.laden_shipping_contents.ton_goods !== undefined)) ? row.attributes.laden_shipping_contents.ton_goods : '';
        return '';
    }
    function getVolumeLossen() {
        if (row.attributes.lossen_unit === undefined) return '';
        if (row.attributes.lossen_unit === 'containers') return (row.attributes.lossen_teu !== undefined) ? row.attributes.lossen_teu : '';
        if (row.attributes.lossen_unit === 'goods') return ((row.attributes.lossen_shipping_contents !== undefined) && (row.attributes.lossen_shipping_contents.ton_goods !== undefined)) ? row.attributes.lossen_shipping_contents.ton_goods : '';
        return '';
    }

    return (
        <TableCell>
            <div className="bezoek">
                {row.attributes.laden && <Typography variant="caption">{t('havenbezoek.visit_type_loading')}: </Typography>}
                {row.attributes.laden && row.attributes.laden_unit && <span>{laden_volume + ' ' + t('havenbezoeken.unit_' + row.attributes.laden_unit)}</span>}
                {row.attributes.laden && row.attributes.lossen && <br />}
                {row.attributes.lossen && <Typography variant="caption">{t('havenbezoek.visit_type_unloading')}: </Typography>}
                {row.attributes.lossen && row.attributes.lossen_unit && <span>{lossen_volume + ' ' + t('havenbezoeken.unit_' + row.attributes.lossen_unit)}</span>}

                {!row.attributes.laden && !row.attributes.lossen && row.attributes.visit_type && volume && (row.attributes.visit_type !== 'not_applicable') && <Typography variant="caption">{t('havenbezoek.visit_type_' + row.attributes.visit_type)}: </Typography>}
                {!row.attributes.laden && !row.attributes.lossen && row.attributes.visit_type && volume && (row.attributes.visit_type !== 'not_applicable') && unit && <span>{volume + ' ' + unit}</span>}

            </div>
        </TableCell>
    );
}
