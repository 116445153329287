import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { AppProvider } from '../../utils/AppContext';

// Components
import Template from '../Template';
import AuthPage from '../AuthPage/AuthPage';
import Havenbezoeken from '../Havenbezoeken/Havenbezoeken';
import Havenbezoek from '../Havenbezoek/Havenbezoek';
import Schepen from '../Schepen/Schepen';
import Schip from '../Schip/Schip';
import Profiel from '../Profiel/UserProfile';
import Page from "../Page/Page";
import { withTranslation } from 'react-i18next';
import helper from "../../assets/helper/";
import Store from "../../Reducers/Store";
import Wrapper from "./Wrapper";
import Client from "../../Model/Client";

// This component ios HoC that prevents the user from accessing a route if he's not logged in
import PrivateRoute from '../PrivateRoute/PrivateRoute';

// Design
import '../../assets/css/template.css';

const history = createBrowserHistory();

const SentryRoute = Sentry.withSentryRouting(Route);
const SentryPrivateRoute = Sentry.withSentryRouting(PrivateRoute);

Sentry.init({
    dsn: "https://ea20b09f199109d9f05a090a0ea3ee5c@o235954.ingest.us.sentry.io/4508399570649088",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

class App extends Component {

    constructor (props) {
        super(props);

        this.setUserInfo = this.setUserInfo.bind(this);
        let userInfo = helper.getUserInfo();

        this.state = {
            language: this.props.i18n.language,
            userInfo: userInfo,
            setUserInfo: this.setUserInfo
        };
    }

    setUserInfo(userInfo) {
        this.setState({
            userInfo: userInfo
        });
    }

    render() {
        return (
            <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
                <Client>
                    <Store>
                        <Wrapper>
                            <AppProvider value={this.state}>
                                <Router history={history}>
                                    <div className="App">
                                        <Template>
                                            <Switch>
                                                <SentryPrivateRoute path="/" component={Havenbezoeken} exact />
                                                <SentryPrivateRoute path="/schepen" component={Schepen} exact />
                                                <SentryPrivateRoute path="/schip/nieuw" component={Schip} exact />
                                                <SentryPrivateRoute path="/schip/:id" component={Schip} exact />
                                                <SentryPrivateRoute path="/havenbezoek/nieuw" component={Havenbezoek} exact />
                                                <SentryPrivateRoute path="/profiel" exact component={(props) => <Profiel {...props} onLanguageChange={this.handleLanguageChange} />} />
                                                <SentryRoute path="/auth/:authType/:id?" render={(props) => <AuthPage {...props} onLanguageChange={this.handleLanguageChange} />} />
                                                <SentryPrivateRoute path="" component={Page} />
                                            </Switch>
                                        </Template>
                                    </div>
                                </Router>
                            </AppProvider>
                        </Wrapper>
                    </Store>
                </Client>
            </Sentry.ErrorBoundary>
        );
    }
}

export default withTranslation()(App);
