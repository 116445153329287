import { gql } from "@apollo/client";

export const CREATE_HAVENBEZOEK = gql`
mutation (
  $aankomstdatum: Date!
  $aankomsttijd: String!
  $vertrekdatum: Date!
  $vertrektijd: String!
  $afwijkend_factuuradres: Boolean!
  $factuuradres_bedrijfsnaam: String!
  $factuuradres_adres: String!
  $factuuradres_huisnr: String
  $factuuradres_huisnrtoevoeging: String
  $factuuradres_postcode: String!
  $factuuradres_plaats: String!
  $factuuradres_land: String!
  $factuuradres_kvk: String!
  $factuuradres_btw: String!
  $bevestiging: Boolean!
  $user: ID!
  $haven: JSON
  $schip: JSON
  $schipper: JSON
  $havenbedrijf: JSON
  $visit_type_loading: Boolean!
  $loading_unit: ENUM_HAVENBEZOEK_LADEN_UNIT
  $loading_teu: Float
  $loading_shipping_contents: JSON
  $visit_type_unloading: Boolean!
  $unloading_unit: ENUM_HAVENBEZOEK_LOSSEN_UNIT
  $unloading_teu: Float
  $unloading_shipping_contents: JSON
) {
  createHavenbezoek(
    data: {
      aankomstdatum: $aankomstdatum
      aankomsttijd: $aankomsttijd
      vertrekdatum: $vertrekdatum
      vertrektijd: $vertrektijd
      afwijkend_factuuradres: $afwijkend_factuuradres
      factuuradres_bedrijfsnaam: $factuuradres_bedrijfsnaam
      factuuradres_adres: $factuuradres_adres
      factuuradres_huisnr: $factuuradres_huisnr
      factuuradres_huisnrtoevoeging: $factuuradres_huisnrtoevoeging
      factuuradres_postcode: $factuuradres_postcode
      factuuradres_plaats: $factuuradres_plaats
      factuuradres_land: $factuuradres_land
      factuuradres_kvk: $factuuradres_kvk
      factuuradres_btw: $factuuradres_btw
      bevestiging: $bevestiging
      user: $user
      haven: $haven
      schip: $schip
      schipper: $schipper
      havenbedrijf: $havenbedrijf
      laden: $visit_type_loading
      laden_unit: $loading_unit
      laden_teu: $loading_teu
      laden_shipping_contents: $loading_shipping_contents
      lossen: $visit_type_unloading
      lossen_unit: $unloading_unit
      lossen_teu: $unloading_teu
      lossen_shipping_contents: $unloading_shipping_contents
      status: 0
    }
  ) {
    data {
      id
      attributes {
        aankomstdatum
        aankomsttijd
        vertrekdatum
        vertrektijd
        afwijkend_factuuradres
        factuuradres_bedrijfsnaam
        factuuradres_adres
        factuuradres_huisnr
        factuuradres_huisnrtoevoeging
        factuuradres_postcode
        factuuradres_plaats
        factuuradres_land
        factuuradres_kvk
        factuuradres_btw
        bevestiging
        haven
        schip
        schipper
        havenbedrijf
        laden
        laden_unit
        laden_teu
        laden_shipping_contents
        lossen_unit
        lossen_teu
        lossen_shipping_contents
        user {
          data {
            id
            attributes {
              email
            }
          }
        }
        status
      }
    }
  }
}
`;

export const UPDATE_MESSAGE = gql`
mutation updateBericht( $id : ID! $gelezen: Boolean! ) {
  updateBericht( id: $id, data: { Gelezen: $gelezen } ) {
    data {
      id
      attributes {
        Gelezen
      }
    }
  }
}
`;

export const DELETE_SHIP = gql`
  mutation deleteShip($id: ID!) {
    deleteSchip(id: $id) {
      data{
        attributes{
          name
        }
      }
    }
  }
`
