import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useShipById, useShips } from "../../Hooks";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

export default () => {
    const { ships, loading, hasError } = useShips()
    const [shipToDelete, setShipToDelete] = useState(null)
    const ref = useRef()
    const { t } = useTranslation()
    const { DeleteShip, deleteShipProps, deleteShip } = useShipById(shipToDelete?.id || null, {
        delete: {
            afterDelete: () => {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }
    })

    useEffect(() => {
        document.title = t('schepen.documentTitle');
    })

    return (
        <div className="Schepen" ref={ref}>
            {hasError && <div className="Error">{hasError}</div>}
            {loading && <CircularProgress />}
            {!loading &&
                <Grid container className="schepen-container" spacing={2}>
                    <Grid item xs={12}>
                        <h1>{t('schepen.title')}</h1>
                        <p>{t('schepen.intro')}</p>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('schepen.tabel-naam')}</TableCell>
                                    <TableCell>{t('schepen.tabel-eni')}</TableCell>
                                    <TableCell>{t('schepen.tabel-tonnage')}</TableCell>
                                    <TableCell>{t('schepen.tabel-scheepstype')}</TableCell>
                                    <TableCell>{t('schepen.tabel-afmeting')}</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ships.map(schip => (
                                    <TableRow key={schip.id} className={schip.attributes.isNew ? 'new' : ''}>
                                        <TableCell component="th" scope="schip">
                                            {schip.attributes.name}
                                        </TableCell>
                                        <TableCell>{schip.attributes.eni}</TableCell>
                                        <TableCell>{schip.attributes.tonnage280}t (2,8m), {schip.attributes.tonnagemax}t (max)</TableCell>
                                        <TableCell>{t('scheepstype.' + schip.attributes.scheepstype.data.attributes.identifier)}</TableCell>
                                        <TableCell>{schip.attributes.lengte}m x {schip.attributes.breedte}m x {schip.attributes.diepte}m</TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex' }}>
                                                <Tooltip title={t('algemeen.edit')}>
                                                    <Link to={`/schip/${schip.id}`}>
                                                        <IconButton>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                                <Tooltip title={t('algemeen.delete')}>
                                                    <Link onClick={() => {
                                                        setShipToDelete(schip)
                                                        deleteShip(schip)
                                                    }} to={'#'}>
                                                        <IconButton>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link className="btn" to={'/schip/nieuw'}>
                                <Button label="Submit"
                                    primary="true"
                                    className="new-schip"
                                    variant="contained">{t('schepen.btn-toevoegen')}
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>

                </Grid>
            }
            <DeleteShip {...deleteShipProps} />
        </div>
    );

}
