

export const BezoekDefinition = {
    visit: {
        aankomstdatum: new Date(),
        vertrekdatum: new Date(),
        aankomsttijd: new Date(),
        vertrektijd: new Date(),
        status: 0,
        afwijkend_factuuradres: false,
        factuuradres_bedrijfsnaam: '',
        factuuradres_adres: '',
        factuuradres_huisnr: '',
        factuuradres_huisnrtoevoeging: '',
        factuuradres_postcode: '',
        factuuradres_plaats: '',
        factuuradres_land: '',
        factuuradres_kvk: '',
        factuuradres_btw: '',
        bevestiging: true,
        referentieId: '',
        haven: {},
        schip: {},
        schipper: {},
        user: 0,
        havenbedrijf: {},
        visit_type: 'loading',
        unit: 'containers',
        teu: 0,
        shipping_contents: {
            ton_goods: '',
            containers20: 0,
            containers40: 0,
            containers45: 0,
        },
        visit_type_loading: false,
        loading_unit: 'containers',
        loading_teu: 0,
        loading_shipping_contents: {
            ton_goods: '',
            containers20: 0,
            containers40: 0,
            containers45: 0,
        },
        visit_type_unloading: false,
        unloading_unit: 'containers',
        unloading_teu: 0,
        unloading_shipping_contents: {
            ton_goods: '',
            containers20: 0,
            containers40: 0,
            containers45: 0,
        }
    },
    errors: {
        formHasError: false,
        serverError: false,
        aankomstdatum: false,
        vertrekdatum: false,
        aankomsttijd: false,
        vertrektijd: false,
        afwijkend_factuuradres: false,
        factuur_bedrijfsnaam: false,
        factuuradres_adres: false,
        factuuradres_huisnr: false,
        factuuradres_huisnrtoevoeging: false,
        factuuradres_postcode: false,
        factuuradres_plaats: false,
        factuuradres_land: false,
        factuuradres_kvk: false,
        factuuradres_btw: false,
        bevestiging: true,
        referentieId: false,
        haven: false,
        schip: false,
        schipper: false,
        user: false,
        havenbedrijf: false,
        visit_type: false,
        unit: false,
        teu: false,
        ton_goods: false,
        visit_type_loading: false,
        loading_unit: false,
        loading_teu: false,
        loading_ton_goods: false,
        visit_type_unloading: false,
        unloading_unit: false,
        unloading_teu: false,
        unloading_ton_goods: false,
    },
    pending: false,
}