import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import UserMenu from '../UserMenu';
import Support from '../Support';
//import logo from '../../assets/img/logo-port-of-twente.png';
import logo from '../../assets/img/logo_POT_havenbedrijf.svg';


const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

export default function Header(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return (
        <AppBar position="fixed" className={classes.appBar}>
            {process.env.NODE_ENV!=="production" && (
                <div>
                    <small> You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small><br />
                </div>
                )}
            <Toolbar disableGutters>
                <div id="header">
                    <Grid container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start">
                        <div className="logo-container">
                            <img id="logo" src={logo} alt="Logo port of Twente"/>
                        </div>
                        <div className="header-title">
                            <Typography variant="h6">{t('algemeen.portal-title')}</Typography>
                        </div>
                        <div>
                            <Support/>
                            <UserMenu/>
                        </div>
                    </Grid>
                </div>
            </Toolbar>
        </AppBar>
    );
}