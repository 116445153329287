import React, {useEffect} from "react";
import {GET_PAGE_BY_SLUG, GET_PAGE_BY_TAG} from "../../Model/Queries";
import NotFoundPage from '../NotFoundPage';
import ReactMarkdown from "react-markdown";
import {usePage} from "../../Hooks/usePage";

export default (props) => {
    const slug = props.hasOwnProperty('slug') ? props.slug : window.location.pathname.replace('/', '');
    const tag  = props.hasOwnProperty('tag') ? props.tag : null;
    const query = (tag !== null) ? GET_PAGE_BY_TAG : GET_PAGE_BY_SLUG;
    const {page: data, loading, is404} = usePage({slug: slug, tag: tag, query: query});

    useEffect(() => {
        if (!loading) {
            document.title = data.Title;
        }
    }, [data, loading])

    return (
        <>
            {!loading &&
            <div className="page">
                {(!is404) &&
                <>
                    <h1>{data.Title}</h1>
                    <ReactMarkdown>{data.Content}</ReactMarkdown>
                </>
                }
                {is404 &&
                <NotFoundPage />
                }
            </div>
            }
        </>
    )
}