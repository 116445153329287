import React from "react";
import Page from "../Page/Page";
import Grid from "@material-ui/core/Grid";
import Ship from "./Inputs/Ship";
import {useTranslation} from "react-i18next";
import Port from "./Inputs/Port";
import Date from "./Inputs/Date";

import BillingAddress from "./Inputs/BillingAddress";
import {usePortVisit} from "../../Hooks/usePortVisit";
import LoadingOptions from "./Inputs/LoadingOptions";
import Confirm from "./Inputs/Confirm";

export default () => {
    const {errors} = usePortVisit();
    const {t} = useTranslation();

    return (
        <div className="havenbezoek">
            <div className="havenbezoek-container">
                <Page tag="port_visit" />

                <section className="form">
                    <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                        <Grid container spacing={2}>
                            {errors.formHasError &&
                            <Grid item xs={12}>
                                <span className="error-message">{t('havenbezoek.foutmelding-validatie')}</span>
                            </Grid>
                            }
                            {errors.serverError &&
                            <Grid item xs={12}>
                                <span className="error-message">{t('havenbezoek.foutmelding-server')}</span>
                            </Grid>
                            }
                            <Ship />
                            <Port />
                            <Date />
                            <LoadingOptions />
                            <BillingAddress />
                            <Confirm />
                        </Grid>
                    </form>
                </section>
            </div>
        </div>
    )
}
