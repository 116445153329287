import { gql } from '@apollo/client';

export const GET_PAGES_BY_LANG = gql`
  query getPages($language: String!, $inMenu: Boolean!) {
    pages(
      filters: { Lang: {eq: $language}, inMenu: {eq: $inMenu} }){
      data {
        attributes {
          Title
          Slug
        }
      }
    }
  }
`;

export const GET_PAGE_BY_SLUG = gql`
  query getPages($slug: String!, $language: String!) {
    pages(filters: { Slug: {eq: $slug}, Lang: {eq: $language} }) {
      data {
        attributes {
          Title
          Content
        }
      }
    }
  }
`;

export const GET_PAGE_BY_TAG = gql`
  query getPages($tag: String!, $language: String!) {
    pages(filters: { Tag: { eq: $tag }, Lang: { eq: $language } }) {
      data {
        attributes {
          Title
          Slug
          Content
          Intro
        }
      }
    }
  }
`;

export const GET_PORTS = gql`
  query {
    havens {
      data {
        id
        attributes {
          name
          haven_id
          havenbedrijven {
            data {
              id
              attributes {
                name
                relatieNr
                expired
              }
            }
          }
        }
      }
    }
  }
`;

// export const GET_USER_DEPRECATED = gql`
//     query getSchipper($userID: ID!, $makeCall: Boolean!) {
//         schipper(id: $userID) @include(if: $makeCall) {
//             id
//             bedrijfsnaam
//             telefoon_schip
//             telefoon_kantoor
//             eigenaar_adres
//             eigenaar_huisnr
//             eigenaar_huisnrtoevoeging
//             eigenaar_postcode
//             eigenaar_plaats
//             eigenaar_land
//             eigenaar_kvk
//             eigenaar_btw
//             factuur_bedrijfsnaam
//             factuur_adres
//             factuur_postcode
//             factuur_plaats
//             factuur_land
//             factuur_kvk
//             factuur_btw
//             taal
//             afwijkend_factuuradres
//             user {
//               id
//               username
//               email
//             }
//         }
//     }
// `;

export const GET_USER = gql`
  query getSchipper($userId: ID!) {
    schippers(filters: { user: { id: {eq: $userId} } }) {
      data {
        id
        attributes {
          name
          bedrijfsnaam
          telefoon_schip
          telefoon_kantoor
          eigenaar_adres
          eigenaar_huisnr
          eigenaar_huisnrtoevoeging
          eigenaar_postcode
          eigenaar_plaats
          eigenaar_land
          eigenaar_kvk
          eigenaar_btw
          factuur_bedrijfsnaam
          factuur_adres
          factuur_huisnr
          factuur_huisnrtoevoeging
          factuur_postcode
          factuur_plaats
          factuur_land
          factuur_kvk
          factuur_btw
          taal
          afwijkend_factuuradres
          user {
            data {
              id
              attributes {
                username
                email
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_SCHIPS = gql`
  query getUserSchips($userID: ID!, $makeCall: Boolean!) {
    usersPermissionsUser(id: $userID) @include(if: $makeCall) {
      data {
        attributes {
          schips(pagination: { page: 1, pageSize: 100}) {
            data {
              id
              attributes {
                name
                eni
                lengte
                breedte
                diepte
                tonnagemax
                greenaward
                greenaward_certificaat
                scheepstype {
                  data {
                    id
                    attributes {
                      identifier
                      name
                    }
                  }
                }
                afwijkend_factuuradres
                address {
                  factuur_bedrijfsnaam
                  factuur_adres
                  factuur_huisnr
                  factuur_huisnrtoevoeging
                  factuur_postcode
                  factuur_plaats
                  factuur_land
                  factuur_kvk
                  factuur_btw
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PORT_VISITS = gql`
  query {
    havenbezoeks(sort: "id:desc") {
      data {
        id
        attributes {
          aankomstdatum
          aankomsttijd
          vertrekdatum
          vertrektijd
          haven
          havenbedrijf
          schipper
          schip
          status
          kosten
          visit_type
          unit
          shipping_contents
          teu
          laden
          laden_unit
          laden_shipping_contents
          laden_teu
          lossen
          lossen_unit
          lossen_shipping_contents
          lossen_teu
        }
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query($limit: Int!, $start: Int!) {
    landens(filters: { id: { lt: 497 } }, sort: "name:asc", pagination: { start: $start, limit: $limit }) {
      data {
        id
        attributes {
          landcode
          name
        }
      }
    }
  }

`;

export const GET_SHIP_TYPES = gql`
  query {
    scheepstypes {
      data {
        id
        attributes {
          identifier
          name
        }
      }
    }
  }

`;

export const GET_MESSAGES = gql`
  query getBerichten {
    berichten: berichts {
      data {
        id
        attributes {
          createdAt
          Berichtinhoud
          Gelezen
          user {
            data {
              id
              attributes {
                username
                email
              }
            }
          }
        }
      }
    }
  }

`;

export const GET_HELPPAGE = gql`
  query getHelpPage($tag: String, $language: String){
    pages(filters: { Tag: { eq: $tag }, Lang: { eq: $language } } ) {
      data{
        id
        attributes{
          Title
          Content
          Lang
          Tag
        }
      }
    }
  }
`;
